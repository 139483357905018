import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, NgForm } from "@angular/forms";
import { Globals } from "../../common/global";
import * as moment from "moment";
import { environment } from "../../../environments/environment";
import * as mapboxgl from "mapbox-gl";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ActivatedRoute, Route, Router } from "@angular/router";
@Component({
  selector: "app-advance-booking",
  templateUrl: "./advance-booking.component.html",
  styleUrls: ["./advance-booking.component.css"],
})
export class AdvanceBookingComponent implements OnInit {
  public Editor = ClassicEditor;
  @ViewChild("mapContainer", { static: true }) mapContainer!: ElementRef;
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  map: any = mapboxgl.Map;
  currentTime!: string;
  geometryData: any;
  isShapeCreated: boolean = false;
  operator_id = this.Globals.getUser()._id;
  operatorDetails = this.Globals.getOperatorDetails();
  allcountry: any;
  countryShortCode: any;
  style = "mapbox://styles/mapbox/streets-v11";
  lat = 37.75;
  lng = -122.41;
  isDivVisible: boolean = false;
  startDate = moment().format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");
  searchQuery: string = "";
  suggestions: any[] = [];
  hideCountry: boolean = true;
  cityAdress: any;
  imageSrc: any;
  imgfile: any;
  hashArr: any[] = [];
  hashTags: string = "";
  AuthDetail: any;
  countryDetail: any;
  mainObj: any;
  category_data: any;
  isOpen: boolean = false;
  sub_category_data: any;
  sub_Category_Id: any;
  category_Id: any;
  category_Name: any;
  sub_Category_Name: any;
  isSubOpen: boolean = false;
  daysOfWeek: any[] = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  selectedDaysArray: any[] = this.daysOfWeek.slice();
  modeltitle: any;
  page = 0;
  pageSize = 10;
  data = [];
  totalcount: any = 0;
  detail: any;
  locktypes = [];
  bookingType = [
    {
      item: "Self Serve",
      value: "self_serve",
    },
    {
      item: "RentX",
      value: "rentx",
    },
    {
      item: "Activities",
      value: "activities",
    },
  ];
  selectedSuggestion: any = null;
  isWaiverDisplay: boolean = true;
  is_ending_pic_mandatory: Boolean = true;
  timings = ["Hour", "Day", "Week", "Month", "Yearly"];
  duration = [
    "0.5",
    "1",
    "1.5",
    "2",
    "2.5",
    "3",
    "3.5",
    "4",
    "4.5",
    "5",
    " 5.5",
    "6",
    "6.5",
    "7",
    "7.5",
    "8",
    "8.5",
    "9",
    "9.5",
    "10",
    "10.5",
    "11",
    "11.5",
    "12",
  ];
  currency: any = localStorage.getItem("CUR");
  maxValue: number = 24;
  valueRepeat: boolean = true;
  now_serving: any;
  next_number_of_queue: any;
  in_queue: any;
  enable_queue: any;
  brand_name = [
    { name: "Frekis", checked: false },
    { name: "Sharekayak", checked: false },
    { name: "OdeServices", checked: false },
  ];
  selectedOptions: any;
  brandSelectionError: boolean = false;
  request_licence: boolean = false;
  licenseTypelist: any[] = [];
  pId: any;

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals,
    private router: Router
  ) {
    this.setCurrentTime();
    // mapboxgl!.accessToken = environment.mapbox.accessToken;
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
  }
  transformBrandName(name: string): string {
    return name === "OdeServices" ? "Shrvee" : name;
  }
  toggleDay(day: string) {
    if (this.selectedDaysArray.includes(day)) {
      this.selectedDaysArray = this.selectedDaysArray.filter((d) => d !== day);
    } else {
      this.selectedDaysArray.push(day);
    }
  }
  onLicenseDisplayChange(request_licence: boolean) {
    if (!request_licence) {
      this.detail.license_type = this.licenseTypelist[0];
    }
  }
  setValue(newValue: boolean) {
    this.valueRepeat = newValue;
  }
  searchLocation() {
    if (this.searchQuery) {
      this.apiCallingService
        .getLocationSuggestions(this.searchQuery)
        .subscribe((data: any[]) => {
          this.suggestions = data;
        });
    } else {
      this.suggestions = [];
    }
  }

  ngOnInit(): void {
    this.getList();
    this.getCategoryList();
    this.getLockTypes();
    this.getLicenseType();
    this.http.get("assets/countries.json").subscribe((data) => {
      this.allcountry = data;
    });

    setTimeout(() => {
      this.map = new mapboxgl.Map({
        container: "map",
        style: this.style,
        zoom: 13,
        center: [72.5713621, 23.022505],
      });
      this.map.addControl(new mapboxgl.FullscreenControl());
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        "top-left"
      );
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        placeholder: "Search with address or name",
        marker: false,
      });
      this.map.addControl(geocoder);
      this.map.on("load", () => {
        this.getDetails(72.5713621, 23.022505);
      });
    }, 1);
    this.AuthDetail = localStorage.getItem("Auth-user");
    this.AuthDetail = JSON.parse(this.AuthDetail);
  }
  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this.isSubOpen = false;
  }
  toggleSubDropdown() {
    this.isSubOpen = !this.isSubOpen;
  }
  getLicenseType() {
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.RENT_PACKAGE.GET_LICENSE_TYPE)
      .subscribe((res) => {
        this.licenseTypelist = res.data;
      });
  }
  getCurrentCountry(lat: any, lng: any) {
    let url =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      +lng +
      ", " +
      lat +
      ".json?access_token=" +
      environment.mapbox.accessToken;
    let geodata;
    this.apiCallingService.callAPI({}, "GET", url).subscribe((res) => {
      geodata = res.features;
      let c = geodata.find((cn: any) => {
        if (cn.id.includes("country")) {
          return cn;
        }
      });
      this.countryShortCode = c.properties.short_code;
    });
  }

  changeStartTime(time: any) {
    if (
      !this.detail.start_date ||
      !this.detail.end_date ||
      this.detail.start_date == "" ||
      this.detail.end_date == ""
    ) {
      this.detail.check_in_time = null;
      this.toastr.error("Please choose date first");
    } else if (
      moment().toString() >=
      moment(
        `${this.detail.start_date} ${this.detail.check_in_time}`
      ).toString()
    ) {
      this.detail.check_in_time = null;
      this.toastr.error("Please choose time greater then current");
    } else if (
      this.detail.check_out_time &&
      this.detail.check_out_time != "" &&
      moment(
        `${this.detail.start_date} ${this.detail.check_in_time}`
      ).toString() >=
        moment(
          `${this.detail.start_date} ${this.detail.check_out_time}`
        ).toString()
    ) {
      this.detail.check_in_time = null;
      this.detail.check_out_time = null;
      this.toastr.error(
        "Please choose time check in time less then check out time"
      );
    }
  }

  changeEndTime(date: any) {
    if (
      !this.detail.start_date ||
      !this.detail.end_date ||
      this.detail.start_date == "" ||
      this.detail.end_date == ""
    ) {
      this.detail.check_out_time = null;
      this.toastr.error("Please choose date first");
    } else if (!this.detail.check_in_time || this.detail.check_in_time == "") {
      this.detail.check_out_time = null;
      this.toastr.error("Please choose check in time first");
    } else if (
      moment(
        `${this.detail.start_date} ${this.detail.check_in_time}`
      ).toString() >=
      moment(
        `${this.detail.start_date} ${this.detail.check_out_time}`
      ).toString()
    ) {
      this.detail.check_out_time = null;
      this.toastr.error("Please check end time greater then start time");
    }
  }

  changeStartDate(date: any, type: String) {
    this.detail.check_in_time = null;
    this.detail.check_out_time = null;
    if (
      this.detail.end_date &&
      this.detail.start_date != "" &&
      this.detail.start_date >= this.detail.end_date
    ) {
      this.detail.start_date = null;
      this.detail.end_date = null;
      this.toastr.error(
        "please choose start date less than or equal to end date"
      );
    }
  }

  changeEndDate(date: any) {
    this.detail.check_in_time = null;
    this.detail.check_out_time = null;
    if (!this.detail.start_date || this.detail.start_date == "") {
      this.detail.end_date = null;
      this.toastr.error("please choose Start date");
    } else if (this.detail.start_date > this.detail.end_date) {
      this.detail.end_date = null;
      this.toastr.error(
        "please choose end date greater than or equal to start date "
      );
    }
  }

  changeTiming(e: any) {
    if (e.target.value == "Minute") {
      this.maxValue = 60;
    } else if (e.target.value == "Hour") {
      this.maxValue = 12;
    } else if (e.target.value == "Day") {
      this.maxValue = 365;
    } else if (e.target.value == "Week") {
      this.maxValue = 52;
    } else if (e.target.value == "Month") {
      this.maxValue = 12;
    }
  }

  selectSuggestion(suggestion: any) {
    this.selectedSuggestion = suggestion;
    this.searchQuery = suggestion.display_name; // Set the search query to the selected suggestion
    this.suggestions = [];
  }
  queueModel(targetModal: NgbModal) {
    this.isDivVisible = true;
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
  }
  // nextModel(targetModal: NgbModal) {
  //   this.isDivVisible = true
  //   this.modalService.open(targetModal, {
  //     centered: true,
  //     backdrop: "static",
  //   });
  //   this.nextNumberQueue()
  // }
  nextNumberQueue() {
    let uploadData = { booking_package_id: this.detail._id };
    // this.uploadData.booking_package_id = this.detail._id
    this.apiCallingService
      .callAPI(
        uploadData,
        "POST",
        ConfigApi.URLS.ADVANCE_BOOKING.NEXT_NUMBER_QUEUE
      )
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            // this.data = res.data;
            this.toastr.success(res.msg);
            this.closeBtnClick();
            console.log(res.data);
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
  }
  manualNumberQueue() {
    let uploadData = {
      queue_number: this.detail.queue_number,
      booking_package_id: this.detail._id,
    };
    this.apiCallingService
      .callAPI(
        uploadData,
        "POST",
        ConfigApi.URLS.ADVANCE_BOOKING.MANUAL_NUMBER_QUEUE
      )
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.closeBtnClick();
            console.log(res.data);
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
  }
  openMapBoxModel(targetModal: NgbModal) {
    this.isDivVisible = true;
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    setTimeout(() => {
      (mapboxgl as any).accessToken = environment.mapbox.accessToken;

      this.map = new mapboxgl.Map({
        container: "map",
        style: this.style,
        zoom: 13,
        center: [72.5713621, 23.022505],
      });
      this.map.addControl(new mapboxgl.FullscreenControl());
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        "top-left"
      );
      const geocoder = new MapboxGeocoder({
        accessToken: environment.mapbox.accessToken,
        mapboxgl: mapboxgl,
      });
      this.map.addControl(geocoder);
      geocoder.on("result", (event) => {
        this.mainObj = event.result;
        // Extract the coordinates from the search result
        const latitude = event.result.center[1];
        const longitude = event.result.center[0];

        // Update the map's center to the selected coordinates
        this.map.setCenter([longitude, latitude]);
      });
      // let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(htmlofPopup);
      // let m = new mapboxgl.Marker()
      //   .setLngLat([this.mainObj.center[0], this.mainObj.center[1]])
      //   .setPopup(popup)
      //   .addTo(this.map);
    });
  }

  saveLocation() {
    this.detail.address = this.mainObj.place_name
      ? this.mainObj.place_name
      : "";
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.detail = Object.assign({}, data);
    this.brand_name.forEach((item) => {
      item.checked = this.detail?.brand_name?.includes(item.name);
    });
    this.modeltitle = title;
    this.imageSrc = this.detail.poster_image;
    const operatorCountry = this.allcountry.find(
      (val: any, i: any) =>
        val.name == this.AuthDetail?.countryObject?.countryName
    );
    let filterObj = data;
    if (this.modeltitle == "Queue Dashboard") {
      this.packDetail();
    }
    if (this.modeltitle === "Create Booking Package") {
      console.log("this.detail", this.detail);
      this.detail.description ? this.detail.description : "";
      if (!this.detail.video_link) {
        this.detail.video_link = "https://atz.ai/safety/";
      }
    }
    if (this.modeltitle === "Edit Package") {
      this.valueRepeat = this.detail.is_activities_pack_onetime;
      this.hashArr = this.detail.hashtags;
      this.selectedDaysArray = this.detail.week_days;
      this.detail.country = operatorCountry.name;
      this.detail.address;
      this.detail.start_date = moment(
        this.detail.start_date ?? new Date()
      ).format("YYYY-MM-DD");
      this.detail.end_date = moment(this.detail.end_date ?? new Date()).format(
        "YYYY-MM-DD"
      );
      //   this.hideCountry = false;
      // } else {
      //   this.hideCountry = true;
    }

    if (Object.keys(data).length == 0) {
      this.detail.booking_type = "self_serve";
      this.detail.isExclusive = false;
      this.detail.type = this.locktypes[0];
      this.detail.isWaiverDisplay = true;
      this.detail.is_user_info_required = false;
      this.detail.enable_pay_later = false;
      this.detail.enable_video = false;
      this.detail.is_ending_pic_mandatory = true;
      this.detail.enable_queue = false;

      this.detail.vat = 6;
      this.detail.country = operatorCountry.name;
      this.detail.duration = this.duration[0];
      this.detail.price = 0;
      this.detail.security_deposit = 0;
      this.detail.extra_fee = 0;
      this.detail.total_asset_allow_in_pack = 1;
      this.detail.total_qtn_of_product_in_pack = 1;
      this.detail.total_user_allow_in_pack = 1;
      this.detail.request_licence = false;
      this.detail.license_type = this.licenseTypelist[0];
    } else {
      if (this.detail.timing == "Minute") {
        this.maxValue = 60;
      } else if (this.detail.timing == "Hour") {
        this.maxValue = 24;
      } else if (this.detail.timing == "Day") {
        this.maxValue = 365;
      } else if (this.detail.timing == "Week") {
        this.maxValue = 52;
      } else if (this.detail.timing == "Month") {
        this.maxValue = 12;
      }
    }
  }
  getDetails(lat: any, lng: any) {
    let url =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      +lng +
      ", " +
      lat +
      ".json??country=" +
      this.countryShortCode +
      "&types=address&access_token=" +
      environment.mapbox.accessToken;
    let geodata;
    this.apiCallingService.callAPI({}, "GET", url).subscribe((res) => {});
  }
  closeBtnClick() {
    this.modalService.dismissAll();
    this.hashArr = [];
    // this.imageSrc = ''
  }
  getList() {
    let page = this.page == 0 ? 0 : this.page - 1;

    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.ADVANCE_BOOKING.ADVANCE_LIST +
          "?operator_id=" +
          this.operator_id +
          "&page=" +
          page +
          "&limit=" +
          this.pageSize
      )
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.data = res.data;
            this.totalcount = res.total;
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
  }
  packDetail() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.ADVANCE_BOOKING.PACK_DETAIL + this.detail._id
      )
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.now_serving = res.data.queue_object.now_serving;
            this.next_number_of_queue =
              res.data.queue_object.next_number_of_queue;
            this.in_queue = res.data.queue_object.in_queue;
            this.toastr.success(res.msg);
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
  }
  getCategoryList() {
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.ADVANCE_BOOKING.CATEGORY_LIST)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.category_data = res.data;
          // this.toastr.success(res.msg);
        }
      });
  }
  getSubCategoryList(id: any, category_name: any) {
    this.category_Id = id;
    this.detail.category_name = category_name;
    this.sub_Category_Id = "";
    this.detail.sub_category_name = "";
    this.isOpen = false;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.ADVANCE_BOOKING.SUB_CATEGORY_LIST +
          "?category_id=" +
          this.category_Id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.sub_category_data = res.data;
        }
      });
  }
  getSubList(id: any, sub_category_name: any) {
    this.sub_Category_Id = id;
    this.detail.sub_category_name = sub_category_name;
    this.isSubOpen = false;
  }
  getLockTypes() {
    let user_id = this.Globals.getUser()._id;
    let query = "?user_id=" + user_id;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_LOCK_TYPE + query)
      .subscribe((res) => {
        this.locktypes = res.data;
      });
  }

  loadPage(page: number) {
    this.page = page;
    this.getList();
  }
  delete(): void {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.LEASE_PACKAGE.DELETE + "/" + this.detail._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Successfully deleted");
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }

  setCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    this.currentTime = `${hours}:${minutes}`;
  }

  pushData(event: any) {
    if (event.key == "Enter") {
      event.preventDefault();
      this.hashArr.push(this.hashTags);
      this.hashTags = "";
      event.stopPropagation();
      return false;
    }
  }

  removeTag(tag: any) {
    const idx = this.hashArr.indexOf(tag);
    this.hashArr.splice(idx, idx !== -1 ? 1 : 0);
  }
  toggleBrandSelection(brand: any) {
    brand.checked = !brand.checked;

    if (this.brand_name.some((brand) => brand.checked)) {
      this.brandSelectionError = false;
    }
  }
  onSubmit(f: any) {
    const isBrandSelected = this.brand_name.some((brand) => brand.checked);
    if (!isBrandSelected) {
      this.brandSelectionError = true;
      return;
    }
    this.brandSelectionError = false;
    let newBrand = this.brand_name
      .filter((opt) => opt.checked)
      .map((opt) => opt);

    this.selectedOptions = newBrand.map((item) => item.name);
    const CountryDetail = this.allcountry.find(
      (val: any, i: any) => val.name == f.value.country
    );
    const timeArray = f.value.check_in_time.split(":");
    const endtimeArray = f.value.check_out_time.split(":");
    let hours = parseInt(timeArray[0]);
    const minutes = parseInt(timeArray[1]);
    if (f.value.check_in_time.toLowerCase().includes("pm") && hours !== 12) {
      hours += 12;
    } else if (
      f.value.check_in_time.toLowerCase().includes("am") &&
      hours === 12
    ) {
      hours = 0;
    }
    let hours1 = parseInt(endtimeArray[0]);
    const minutes1 = parseInt(endtimeArray[1]);

    if (f.value.check_out_time.toLowerCase().includes("pm") && hours1 !== 12) {
      hours1 += 12;
    } else if (
      f.value.check_out_time.toLowerCase().includes("am") &&
      hours1 === 12
    ) {
      hours1 = 0;
    }
    const convertedStartTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    const convertedEndTime = `${hours1.toString().padStart(2, "0")}:${minutes1
      .toString()
      .padStart(2, "0")}`;
    this.cityAdress = f.value.address;
    let uploadData = new FormData();
    uploadData.append("user_id", this.Globals.getUser()._id);
    uploadData.append("brand_name", JSON.stringify(this.selectedOptions));
    uploadData.append("title", f.value.title);
    uploadData.append("booking_type", f.value.booking_type);
    uploadData.append("timing", f.value.timing ? f.value.timing : "");
    uploadData.append("check_in_time", convertedStartTime);
    uploadData.append("check_out_time", convertedEndTime);
    uploadData.append("duration", f.value.duration ? f.value.duration : "");
    uploadData.append("price", f.value.price);
    uploadData.append(
      "security_deposit",
      f.value.security_deposit ? f.value.security_deposit : 0
    );
    uploadData.append("name", "Per " + f.value.timing);
    uploadData.append(
      "description",
      f.value.description ? f.value.description : ""
    );
    uploadData.append("type", f.value.type ? f.value.type : "");
    uploadData.append("vat", f.value.vat ? f.value.vat : 0);
    uploadData.append("operator_id", this.operator_id);

    if (
      this.detail.booking_type == "activities" ||
      this.detail.booking_type == "rentx"
    ) {
      uploadData.append("category_name", this.detail.category_name);
      uploadData.append("sub_category_name", this.detail.sub_category_name);
      uploadData.append(
        "category_id",
        this.category_Id ? this.category_Id : ""
      );
      uploadData.append(
        "sub_category_id",
        this.sub_Category_Id ? this.sub_Category_Id : ""
      );
    }

    uploadData.append("start_date", f.value.start_date);
    uploadData.append("end_date", f.value.end_date);
    uploadData.append(
      "week_days",
      this.selectedDaysArray ? JSON.stringify(this.selectedDaysArray) : ""
    );
    if (this.detail.booking_type !== "activities") {
      uploadData.append("extra_fee", f.value.extra_fee ? f.value.extra_fee : 0);
    }
    if (this.detail.booking_type == "activities") {
      uploadData.append(
        "is_activities_pack_onetime",
        this.valueRepeat.toString()
      );
    }
    uploadData.append(
      "total_asset_allow_in_pack",
      f.value.total_asset_allow_in_pack ? f.value.total_asset_allow_in_pack : 0
    );
    uploadData.append(
      "total_qtn_of_product_in_pack",
      f.value.total_qtn_of_product_in_pack
        ? f.value.total_qtn_of_product_in_pack
        : 0
    );
    uploadData.append(
      "total_user_allow_in_pack",
      f.value.total_user_allow_in_pack ? f.value.total_user_allow_in_pack : 0
    );
    uploadData.append(
      "latitude",
      this.mainObj && this.mainObj.center[1]
        ? this.mainObj.center[1]
        : this.detail.location.coordinates[1]
    );
    uploadData.append(
      "longitude",
      this.mainObj && this.mainObj.center[0]
        ? this.mainObj.center[0]
        : this.detail.location.coordinates[0]
    );
    uploadData.append(
      "address",
      f.value.address ? f.value.address : this.detail.address
    );

    if (this.detail.booking_type !== "self_serve") {
      uploadData.append(
        "enable_queue",
        this.detail.enable_queue ? this.detail.enable_queue : false
      );
    }
    if (this.detail.booking_type !== "activities") {
      uploadData.append(
        "is_ending_pic_mandatory",
        this.detail.is_ending_pic_mandatory
          ? this.detail.is_ending_pic_mandatory
          : false
      );
    }
    uploadData.append("isWaiverDisplay", this.detail.isWaiverDisplay);
    uploadData.append("request_licence", this.detail.request_licence);
    uploadData.append(
      "license_type",
      this.detail.request_licence ? this.detail.license_type : ""
    );
    uploadData.append(
      "is_user_info_required",
      this.detail.is_user_info_required && this.detail.is_user_info_required
        ? this.detail.is_user_info_required
        : false
    );
    uploadData.append(
      "enable_pay_later",
      this.detail.enable_pay_later && this.detail.enable_pay_later
        ? this.detail.enable_pay_later
        : false
    );
    uploadData.append(
      "enable_video",
      this.detail.enable_video && this.detail.enable_video
        ? this.detail.enable_video
        : false
    );
    uploadData.append(
      "video_link",
      this.detail.video_link && this.detail.video_link
        ? this.detail.video_link
        : ""
    );
    uploadData.append("isExclusive", f.value.isExclusive);
    uploadData.append(
      "poster_image",
      this.imgfile ? this.imgfile : this.detail.poster_image
    );
    uploadData.append("hashtags", JSON.stringify(this.hashArr));

    if (this.modeltitle == "Create Booking Package") {
      uploadData = new FormData();
      uploadData.append("user_id", this.Globals.getUser()._id);
      uploadData.append("brand_name", JSON.stringify(this.selectedOptions));
      uploadData.append("title", f.value.title);
      uploadData.append("booking_type", f.value.booking_type);
      uploadData.append("timing", f.value.timing ? f.value.timing : "");
      uploadData.append("check_in_time", convertedStartTime);
      uploadData.append("check_out_time", convertedEndTime);
      uploadData.append("duration", f.value.duration ? f.value.duration : "");
      uploadData.append("price", f.value.price);
      uploadData.append(
        "security_deposit",
        f.value.security_deposit ? f.value.security_deposit : 0
      );
      uploadData.append("name", "Per " + f.value.timing);
      uploadData.append(
        "description",
        f.value.description ? f.value.description : ""
      );
      uploadData.append("type", f.value.type ? f.value.type : "");
      uploadData.append("vat", f.value.vat ? f.value.vat : 0);
      uploadData.append("operator_id", this.operator_id);

      if (
        this.detail.booking_type == "activities" ||
        this.detail.booking_type == "rentx"
      ) {
        uploadData.append("category_name", this.detail.category_name);
        uploadData.append("sub_category_name", this.detail.sub_category_name);
        uploadData.append(
          "category_id",
          this.category_Id ? this.category_Id : ""
        );
        uploadData.append(
          "sub_category_id",
          this.sub_Category_Id ? this.sub_Category_Id : ""
        );
      }
      uploadData.append("start_date", f.value.start_date);
      uploadData.append("end_date", f.value.end_date);
      uploadData.append(
        "week_days",
        this.selectedDaysArray ? JSON.stringify(this.selectedDaysArray) : ""
      );
      if (this.detail.booking_type !== "activities") {
        uploadData.append(
          "extra_fee",
          f.value.extra_fee ? f.value.extra_fee : 0
        );
      }
      if (this.detail.booking_type == "activities") {
        uploadData.append(
          "is_activities_pack_onetime",
          this.valueRepeat.toString()
        );
      }
      uploadData.append(
        "total_asset_allow_in_pack",
        f.value.total_asset_allow_in_pack
          ? f.value.total_asset_allow_in_pack
          : 0
      );
      uploadData.append(
        "total_qtn_of_product_in_pack",
        f.value.total_qtn_of_product_in_pack
          ? f.value.total_qtn_of_product_in_pack
          : 0
      );
      uploadData.append(
        "total_user_allow_in_pack",
        f.value.total_user_allow_in_pack ? f.value.total_user_allow_in_pack : 0
      );
      if (this.detail.booking_type !== "self_serve") {
        uploadData.append(
          "enable_queue",
          this.detail.enable_queue ? this.detail.enable_queue : false
        );
      }

      if (this.detail.booking_type !== "activities") {
        uploadData.append(
          "is_ending_pic_mandatory",
          this.detail.is_ending_pic_mandatory
            ? this.detail.is_ending_pic_mandatory
            : false
        );
      }
      uploadData.append("isWaiverDisplay", this.detail.isWaiverDisplay);
      uploadData.append("request_licence", this.detail.request_licence);
      uploadData.append(
        "license_type",
        this.detail.request_licence ? this.detail.license_type : ""
      );
      uploadData.append(
        "is_user_info_required",
        this.detail.is_user_info_required
      );
      uploadData.append("enable_pay_later", this.detail.enable_pay_later);
      uploadData.append(
        "video_link",
        f.value.video_link == "" || f.value.video_link == null
          ? "https://atz.ai/safety/"
          : f.value.video_link
      );
      uploadData.append("enable_video", this.detail.enable_video);
      uploadData.append("isExclusive", f.value.isExclusive);
      uploadData.append("latitude", this.mainObj.center[1]);
      uploadData.append("longitude", this.mainObj.center[0]);
      uploadData.append("country", CountryDetail.name);
      uploadData.append("country_iso_code", CountryDetail.alpha2Code);
      uploadData.append("address", f.value.address);
      uploadData.append("poster_image", this.imgfile);
      uploadData.append("hashtags", JSON.stringify(this.hashArr));
    }
    let url = ConfigApi.URLS.ADVANCE_BOOKING.CREATE;
    let method = "POST";
    if (this.modeltitle == "Edit Package") {
      let url = ConfigApi.URLS.ADVANCE_BOOKING.UPDATE + "/" + this.detail._id;
      method = "PUT";
      this.apiCallingService.callAPI(uploadData, method, url).subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.closeBtnClick();
            this.ngOnInit();
            // this.imageSrc = '';
            this.hashArr = [];
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
    } else {
      this.apiCallingService.callAPI(uploadData, method, url).subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.closeBtnClick();
            this.ngOnInit();
            // this.imageSrc = '';
            this.hashArr = [];
          } else {
            this.toastr.error(res.msg);
          }
        },
        (err) => {
          this.toastr.error(err.error.msg, "Error");
        }
      );
    }
  }
  enableQueue(f: any) {
    const uploadData = new HttpParams().set("enable_queue", f.enable_queue);

    let url = ConfigApi.URLS.ADVANCE_BOOKING.UPDATE + "/" + f._id;
    let method = "PUT";
    this.apiCallingService.callAPI(uploadData, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          // this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }
  deleteData(id: any) {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.ADVANCE_BOOKING.DELETE + "/" + this.detail._id
      )
      .subscribe(
        (res) => {
          console.log(
            "🚀 ~ file: advance-booking.component.ts:772 ~ AdvanceBookingComponent ~ .subscribe ~ res:",
            res
          );
          if (this.apiCallingService.handleSuccess(res)) {
            // this.toastr.success(res.msg);
            this.closeBtnClick();
            this.ngOnInit();
          }
        },
        (err) => {
          this.closeBtnClick();
          this.ngOnInit();
          this.toastr.error(err.error.msg, "Error");
        }
      );
  }
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.imgfile = file;
      this.detail.poster_image = file;
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      reader.readAsDataURL(file);
      this.imageSrc = file;
    }
  }

  viewCouponList(data: any) {
    const packageId = data._id;
    this.router.navigate(["user/advance_booking/advance_book_coupons"], {
      queryParams: { pId: packageId },
    });
  }
}
