<div class="row">
  <div class="col-sm-12 col-md-6 col-12">
    <div class="card dash-card">
      <h3>Packages</h3>
      <div class="row">
        <div class="col-sm-6 col-md-12 col-lg-4 col-xl-4">
          <div class="card card-dashh">
            <div
              class="box1 cursor-pointer"
              [routerLink]="['/user/rent-package']"
            >
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-8 col-xl-8 col-8">
                  <h6 class="text-white">Rental Packages</h6>
                  <h1 class="font-light text-white" style="display: grid">
                    {{ rentpackageCount }}
                  </h1>
                </div>
                <div
                  class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-4 d-flex align-items-center"
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 34 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.5"
                      width="34"
                      height="34"
                      rx="10"
                      fill="#7167E4"
                    />
                    <g clip-path="url(#clip0_54_7058)">
                      <path
                        d="M19.875 11.6044V11.5994L23.4304 13.2757L27 11.8669L17.5 8.11694L8 11.8669L17.5 15.6169L21.0554 14.2132L17.5 12.6707V12.6669L19.875 11.6044Z"
                        fill="white"
                      />
                      <path
                        d="M8 13.1169V24.3669L16.9062 27.8832V16.6332L8 13.1169ZM12.75 24.4082L10.375 23.4707V22.1369L12.75 23.0744V24.4082Z"
                        fill="white"
                      />
                      <path
                        d="M23.4375 14.5232V17.6544L21.0625 18.5919V15.4607L18.0938 16.6332V27.8832L27 24.3669V13.1169L23.4375 14.5232Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_54_7058">
                        <rect
                          width="19"
                          height="20"
                          fill="white"
                          transform="translate(8 8)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-4 col-xl-4">
          <div class="card card-dashh">
            <div
              class="box2 cursor-pointer"
              [routerLink]="['/user/sale-package']"
            >
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-8 col-xl-8 col-8">
                  <h6 class="text-white">Sale Packages</h6>
                  <h1 class="font-light text-white" style="display: grid">
                    {{ allpackageCount }}
                  </h1>
                </div>
                <div
                  class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-4 d-flex align-items-center"
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 34 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.5"
                      width="34"
                      height="34"
                      rx="10"
                      fill="#F5494C"
                    />
                    <g clip-path="url(#clip0_54_7074)">
                      <path
                        d="M19.875 11.6044V11.5994L23.4304 13.2757L27 11.8669L17.5 8.11694L8 11.8669L17.5 15.6169L21.0554 14.2132L17.5 12.6707V12.6669L19.875 11.6044Z"
                        fill="white"
                      />
                      <path
                        d="M8 13.1169V24.3669L16.9062 27.8832V16.6332L8 13.1169ZM12.75 24.4082L10.375 23.4707V22.1369L12.75 23.0744V24.4082Z"
                        fill="white"
                      />
                      <path
                        d="M23.4375 14.5232V17.6544L21.0625 18.5919V15.4607L18.0938 16.6332V27.8832L27 24.3669V13.1169L23.4375 14.5232Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_54_7074">
                        <rect
                          width="19"
                          height="20"
                          fill="white"
                          transform="translate(8 8)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-4 col-xl-4">
          <div class="card card-dashh">
            <div
              class="box3 cursor-pointer"
              [routerLink]="['/user/book_package']"
            >
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-8 col-xl-8 col-8">
                  <h6 class="text-white">Book Packages</h6>
                  <h1 class="font-light text-white" style="display: grid">
                    {{ leasepackageCount }}
                  </h1>
                </div>
                <div
                  class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-4 d-flex align-items-center"
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 34 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.5"
                      width="34"
                      height="34"
                      rx="10"
                      fill="#FB7D40"
                    />
                    <g clip-path="url(#clip0_54_7090)">
                      <path
                        d="M19.875 11.6044V11.5994L23.4304 13.2757L27 11.8669L17.5 8.11694L8 11.8669L17.5 15.6169L21.0554 14.2132L17.5 12.6707V12.6669L19.875 11.6044Z"
                        fill="white"
                      />
                      <path
                        d="M8 13.1169V24.3669L16.9062 27.8832V16.6332L8 13.1169ZM12.75 24.4082L10.375 23.4707V22.1369L12.75 23.0744V24.4082Z"
                        fill="white"
                      />
                      <path
                        d="M23.4375 14.5232V17.6544L21.0625 18.5919V15.4607L18.0938 16.6332V27.8832L27 24.3669V13.1169L23.4375 14.5232Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_54_7090">
                        <rect
                          width="19"
                          height="20"
                          fill="white"
                          transform="translate(8 8)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-12">
    <div class="card dash-card">
      <h3 class="text-white">Bookings</h3>
      <div class="row">
        <div class="col-sm-6 col-md-12 col-lg-6 col-xl-6">
          <div class="card card-dashh">
            <div
              class="box4 cursor-pointer"
              [routerLink]="['/user/advance_booking']"
            >
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9 col-8">
                  <h6 class="text-white">Advance Bookings</h6>
                  <h1 class="font-light text-white" style="display: grid">
                    {{ advanceBooking }}
                  </h1>
                </div>
                <div
                  class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-4 d-flex align-items-center"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.3125 8.4375V5.90625C25.3125 5.23492 25.0458 4.59109 24.5711 4.11639C24.0964 3.64168 23.4526 3.375 22.7812 3.375H19.4062V2.53125C19.4062 2.30747 19.3174 2.09286 19.1591 1.93463C19.0009 1.77639 18.7863 1.6875 18.5625 1.6875C18.3387 1.6875 18.1241 1.77639 17.9659 1.93463C17.8076 2.09286 17.7188 2.30747 17.7188 2.53125V3.375H9.28125V2.53125C9.28125 2.30747 9.19236 2.09286 9.03412 1.93463C8.87589 1.77639 8.66128 1.6875 8.4375 1.6875C8.21372 1.6875 7.99911 1.77639 7.84088 1.93463C7.68264 2.09286 7.59375 2.30747 7.59375 2.53125V3.375H4.21875C3.54742 3.375 2.90359 3.64168 2.42889 4.11639C1.95418 4.59109 1.6875 5.23492 1.6875 5.90625V8.4375H25.3125Z"
                      fill="white"
                    />
                    <path
                      d="M1.6875 10.125V22.7812C1.6875 23.4526 1.95418 24.0964 2.42889 24.5711C2.90359 25.0458 3.54742 25.3125 4.21875 25.3125H22.7812C23.4526 25.3125 24.0964 25.0458 24.5711 24.5711C25.0458 24.0964 25.3125 23.4526 25.3125 22.7812V10.125H1.6875ZM18.2672 14.9842L12.3609 20.0467C12.1995 20.1847 11.9919 20.2567 11.7797 20.2485C11.5675 20.2402 11.3662 20.1521 11.216 20.0019L8.68472 17.4707C8.53102 17.3116 8.44598 17.0984 8.4479 16.8772C8.44982 16.656 8.53856 16.4443 8.695 16.2879C8.85144 16.1315 9.06306 16.0427 9.28429 16.0408C9.50552 16.0389 9.71865 16.1239 9.87778 16.2776L11.8572 18.2571L17.1728 13.7008C17.2565 13.6254 17.3544 13.5675 17.4608 13.5305C17.5672 13.4936 17.6799 13.4783 17.7923 13.4856C17.9047 13.4928 18.0145 13.5226 18.1152 13.5729C18.2159 13.6233 18.3056 13.6934 18.3788 13.7789C18.452 13.8645 18.5074 13.9639 18.5416 14.0712C18.5758 14.1785 18.5882 14.2915 18.5781 14.4037C18.5679 14.5159 18.5354 14.6249 18.4824 14.7243C18.4295 14.8237 18.3571 14.9115 18.2697 14.9825L18.2672 14.9842Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6 col-xl-6">
          <div class="card card-dashh">
            <div
              class="box5 cursor-pointer"
              [routerLink]="['/user/users-booking']"
            >
              <div class="row">
                <div class="col-sm-6 col-md-8 col-lg-9 col-xl-9 col-8">
                  <h6 class="text-white">Users Bookings</h6>
                  <h1 class="font-light text-white" style="display: grid">
                    {{ upcomingBookingCount }}
                  </h1>
                </div>
                <div
                  class="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-4 d-flex align-items-center"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.3125 8.4375V5.90625C25.3125 5.23492 25.0458 4.59109 24.5711 4.11639C24.0964 3.64168 23.4526 3.375 22.7812 3.375H19.4062V2.53125C19.4062 2.30747 19.3174 2.09286 19.1591 1.93463C19.0009 1.77639 18.7863 1.6875 18.5625 1.6875C18.3387 1.6875 18.1241 1.77639 17.9659 1.93463C17.8076 2.09286 17.7188 2.30747 17.7188 2.53125V3.375H9.28125V2.53125C9.28125 2.30747 9.19236 2.09286 9.03412 1.93463C8.87589 1.77639 8.66128 1.6875 8.4375 1.6875C8.21372 1.6875 7.99911 1.77639 7.84088 1.93463C7.68264 2.09286 7.59375 2.30747 7.59375 2.53125V3.375H4.21875C3.54742 3.375 2.90359 3.64168 2.42889 4.11639C1.95418 4.59109 1.6875 5.23492 1.6875 5.90625V8.4375H25.3125Z"
                      fill="white"
                    />
                    <path
                      d="M1.6875 10.125V22.7812C1.6875 23.4526 1.95418 24.0964 2.42889 24.5711C2.90359 25.0458 3.54742 25.3125 4.21875 25.3125H22.7812C23.4526 25.3125 24.0964 25.0458 24.5711 24.5711C25.0458 24.0964 25.3125 23.4526 25.3125 22.7812V10.125H1.6875ZM18.2672 14.9842L12.3609 20.0467C12.1995 20.1847 11.9919 20.2567 11.7797 20.2485C11.5675 20.2402 11.3662 20.1521 11.216 20.0019L8.68472 17.4707C8.53102 17.3116 8.44598 17.0984 8.4479 16.8772C8.44982 16.656 8.53856 16.4443 8.695 16.2879C8.85144 16.1315 9.06306 16.0427 9.28429 16.0408C9.50552 16.0389 9.71865 16.1239 9.87778 16.2776L11.8572 18.2571L17.1728 13.7008C17.2565 13.6254 17.3544 13.5675 17.4608 13.5305C17.5672 13.4936 17.6799 13.4783 17.7923 13.4856C17.9047 13.4928 18.0145 13.5226 18.1152 13.5729C18.2159 13.6233 18.3056 13.6934 18.3788 13.7789C18.452 13.8645 18.5074 13.9639 18.5416 14.0712C18.5758 14.1785 18.5882 14.2915 18.5781 14.4037C18.5679 14.5159 18.5354 14.6249 18.4824 14.7243C18.4295 14.8237 18.3571 14.9115 18.2697 14.9825L18.2672 14.9842Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-6 col-12">
    <div class="card ride-card" [routerLink]="['/user/my-asset-ride-history']">
      <div class="box-ride cursor-pointer">
        <h3>Total Rides</h3>
        <h2>
          {{ ridesCount }}
        </h2>
      </div>
      <div class="d-flex align-items-center">
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_54_7125)">
            <rect x="6" y="2" width="45" height="45" rx="12" fill="#83BE41" />
          </g>
          <path
            d="M37.7967 22.3699H36.4173L35.4096 19.0083C35.0752 17.8929 34.0699 17.1449 32.906 17.1449H30.6136C30.6136 16.4255 30.0284 15.8403 29.3068 15.8403H26.6932C25.9717 15.8403 25.3864 16.4255 25.3864 17.1449H23.0941C21.9302 17.1449 20.9249 17.8929 20.5905 19.0083L19.5828 22.3699H18.2034C17.8427 22.3699 17.55 22.6625 17.55 23.0233C17.55 23.7031 18.1441 24.2993 18.8568 24.6491V31.5065C18.8568 31.8673 19.1495 32.1599 19.5102 32.1599H20.8149C21.1756 32.1599 21.4682 31.8673 21.4682 31.5065V30.2085H34.5318V31.5065C34.5318 31.8673 34.8245 32.1599 35.1852 32.1599H36.4899C36.8506 32.1599 37.1432 31.8673 37.1432 31.5065V24.6491C37.856 24.2993 38.45 23.7031 38.45 23.0233C38.45 22.6625 38.1574 22.3699 37.7967 22.3699ZM21.1492 21.6967L21.8423 19.3823C22.0095 18.8301 22.5177 18.4517 23.0941 18.4517H32.906C33.4824 18.4517 33.9906 18.8301 34.1578 19.3823L34.8509 21.6967C34.952 22.0311 34.7013 22.3699 34.3492 22.3699H21.6509C21.2988 22.3699 21.0481 22.0311 21.1492 21.6967ZM23.4284 27.5949H21.4682C20.7467 27.5949 20.1636 27.0097 20.1636 26.2903C20.1636 25.5687 20.7467 24.9835 21.4682 24.9835C22.5573 24.9835 24.0818 25.8547 24.0818 26.9437C24.0818 27.3023 23.7892 27.5949 23.4284 27.5949ZM30.266 28.5343C30.266 28.7059 30.1274 28.8445 29.9559 28.8445H26.0442C25.8727 28.8445 25.7341 28.7059 25.7341 28.5343V27.7203C25.7341 27.5487 25.8727 27.4101 26.0442 27.4101H29.9559C30.1274 27.4101 30.266 27.5487 30.266 27.7203V28.5343ZM34.5318 27.5949H32.5717C32.2109 27.5949 31.9182 27.3023 31.9182 26.9437C31.9182 25.8547 33.4428 24.9835 34.5318 24.9835C35.2534 24.9835 35.8364 25.5687 35.8364 26.2903C35.8364 27.0097 35.2534 27.5949 34.5318 27.5949Z"
            fill="white"
          />
          <defs>
            <filter
              id="filter0_d_54_7125"
              x="0.5"
              y="-2.38419e-07"
              width="56"
              height="56"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.5" />
              <feGaussianBlur stdDeviation="2.75" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_54_7125"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_54_7125"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
    <div class="card ride-card" [routerLink]="['/user/my-assets']">
      <div class="box-ride cursor-pointer">
        <h3>Total Locks</h3>
        <h2>
          {{ lockCount }}
        </h2>
      </div>
      <div class="d-flex align-items-center">
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_54_7133)">
            <rect x="6" y="2" width="45" height="45" rx="12" fill="#83BE41" />
          </g>
          <path
            d="M21.4507 19.6081V21.9052H21.2979C20.3359 21.9052 19.5537 22.6874 19.5537 23.6494V33.2558C19.5537 34.2178 20.3359 35 21.2979 35H34.7029C35.6649 35 36.4471 34.2178 36.4471 33.2558V23.6449C36.4471 22.6829 35.6649 21.9007 34.7029 21.9007H34.6579V19.6036C34.6579 15.9624 31.6955 13 28.0543 13C24.4131 13.0045 21.4507 15.9669 21.4507 19.6081ZM29.0343 28.8774C28.8994 28.9763 28.8635 29.0707 28.8635 29.228C28.868 29.9338 28.868 30.6351 28.868 31.3408C28.8815 31.633 28.7331 31.9207 28.4724 32.0511C27.8565 32.3658 27.2407 31.9252 27.2407 31.3408V31.3363C27.2407 30.6306 27.2407 29.9248 27.2452 29.219C27.2452 29.0752 27.2137 28.9808 27.0878 28.8864C26.4495 28.4144 26.2382 27.6097 26.5574 26.904C26.8676 26.2162 27.6273 25.8116 28.342 25.9555C29.1377 26.1173 29.6906 26.7556 29.6996 27.5513C29.6996 28.1042 29.4793 28.5537 29.0343 28.8774ZM24.3412 19.6081C24.3412 17.5582 26.009 15.895 28.0543 15.895C30.1042 15.895 31.7674 17.5627 31.7674 19.6081V21.9052H24.3412V19.6081Z"
            fill="white"
          />
          <defs>
            <filter
              id="filter0_d_54_7133"
              x="0.5"
              y="-2.38419e-07"
              width="56"
              height="56"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.5" />
              <feGaussianBlur stdDeviation="2.75" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_54_7133"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_54_7133"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-6 col-12">
    <div class="card income-card">
      <div class="box-income cursor-pointer">
        <h3>Total Income ({{ curr }})</h3>

        <h2>
          {{ totalincome }}
        </h2>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <div
          style="
            background-color: #83be41;
            display: flex;
            justify-content: center;
            border-radius: 20px;
            padding: 15px;
            color: white;
            margin-right: 20px;
          "
        >
          <img src="assets/images/money.png" alt="" width="50" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <div class="card card-border">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div>
            <h4 class="card-title">Revenue</h4>
            <h5 class="card-subtitle">Overview of Latest records</h5>
          </div>
          <div class="ml-auto">
            <div class="dl">
              <select
                class="custom-select"
                [(ngModel)]="select1"
                (change)="changeSaleschart()"
              >
                <option value="day">Daily</option>
                <option value="week">Weekly</option>
                <option value="month">Monthly</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <apx-chart
            [series]="salesChartOptions.series"
            #chartObj
            [chart]="salesChartOptions.chart"
            [xaxis]="salesChartOptions.xaxis"
            [yaxis]="salesChartOptions.yaxis"
            [grid]="salesChartOptions.grid"
            [stroke]="salesChartOptions.stroke"
            [tooltip]="salesChartOptions.tooltip"
            [dataLabels]="salesChartOptions.dataLabels"
            [legend]="salesChartOptions.legend"
            [colors]="salesChartOptions.colors"
            [markers]="salesChartOptions.markers"
          >
          </apx-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card card-border">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div>
            <h4 class="card-title">Rides</h4>
            <h5 class="card-subtitle">Overview of Latest records</h5>
          </div>
          <div class="ml-auto">
            <div class="dl">
              <select
                class="custom-select"
                [(ngModel)]="select2"
                (change)="changeRidechart()"
              >
                <option value="day">Daily</option>
                <option value="week">Weekly</option>
                <option value="month">Monthly</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <apx-chart
            [series]="rideChartOptions.series"
            [chart]="rideChartOptions.chart"
            [xaxis]="rideChartOptions.xaxis"
            [yaxis]="rideChartOptions.yaxis"
            [grid]="salesChartOptions.grid"
            [stroke]="rideChartOptions.stroke"
            [tooltip]="rideChartOptions.tooltip"
            [dataLabels]="rideChartOptions.dataLabels"
            [legend]="rideChartOptions.legend"
            [colors]="rideChartOptions.colors"
            [markers]="rideChartOptions.markers"
          >
          </apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>
