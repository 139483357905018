import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";

import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { Globals } from "../../common/global";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
@Component({
  selector: "app-coupons",
  templateUrl: "./coupons.component.html",
  styleUrls: ["./coupons.component.css"],
})
export class CouponsComponent implements OnInit {
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  today: any = moment(new Date()).format("YYYY-MM-DD");

  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  packages: any = [];
  timings = ["Day", "Week", "Month", "3 Month", "6 Month", "Yearly"];
  currency: any = localStorage.getItem("CUR");
  selectedPakcage: any;
  pattern = /(\w+)$/;
  pId: any;
  coupon_For: string = "";
  packageType: string = "";
  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.pId = params["pId"];
    });

    const url = this.location.path();
    const pathSegments = url.split("/");
    if (pathSegments.length > 2) {
      this.coupon_For = pathSegments[2];
    }

    this.getList();
    this.getPackages();
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);

    this.detail.package_id = this.detail?.package_id?._id;
    this.detail.expire_date = moment(
      this.detail.expire_date ?? new Date()
    ).format("YYYY-MM-DD");
    if (Object.keys(data).length == 0) {
      this.detail.isMultipleUse = false;
      this.detail.type = this.packages && this.packages.timing.toLowerCase();
      this.detail.time = 1;
    }
  }
  keyPressNumbers(event: any) {
    if (
      !this.detail.discountPercent ||
      parseFloat(this.detail.discountPercent) <= 100
    ) {
      console.log("true");
      return true;
    } else {
      console.log("false");
      event.preventDefault();
      return false;
    }
  }

  closeBtnClick() {
    this.modalService.dismissAll();
  }
  getList() {
    let user_id = this.Globals.getUser()._id;
    let operator_id = this.Globals.getUser()._id;

    const currentUrl = window.location.href;
    let packageType = currentUrl.includes("book_package")
      ? "lease_package"
      : currentUrl.includes("rent-package")
      ? "rent_package"
      : currentUrl.includes("sale-package")
      ? "sale_package"
      : currentUrl.includes("advance_booking")
      ? "advance_booking"
      : "rent_package";

    let payload = {
      operator_id: operator_id,
      coupon_for: packageType,
      package_id: this.pId,
    };
    this.apiCallingService
      .callAPI(payload, "POST", ConfigApi.URLS.COUPON.COUPON_BY_PACKAGE)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          this.totalcount = 2;
        }
      });
  }
  getPackages() {
    const body = new HttpParams().set("userId", this.Globals.getUser()._id);
    this.apiCallingService
      .callAPI(body, "POST", ConfigApi.URLS.DASHBOARD.GET_PACKAGE)
      .subscribe((res) => {
        let data = res.data.data.find((res: any) => res._id === this.pId);

        this.packages = data;
        this.detail.type = this.packages.timing.toLowerCase();
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getList();
  }

  delete(): void {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.COUPON.DELETE + "/" + this.detail._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Successfully deleted");
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }
  onSubmit(f: any) {
    var type, time;

    if (f.value.couponType == "time") {
      type = this.detail.type;
      time = this.detail.time;
    } else {
      type =
        this.selectedPakcage && this.selectedPakcage.timing
          ? this.selectedPakcage.timing.toLowerCase()
          : this.detail.type;
      time = 0;
    }

    const body = new HttpParams()
      .set("operator_id", this.Globals.getUser()._id)
      .set("package_id", this.pId)
      .set("couponCode", f.value.couponCode)
      .set("couponType", f.value.couponType)
      .set("discountPercent", f.value.discountPercent ?? 0)
      .set("isMultipleUse", f.value.isMultipleUse)
      .set("expire_date", f.value.expire_date)
      .set("limit", f.value.limit)
      .set("time", time)
      .set("type", type);

    let url = ConfigApi.URLS.COUPON.CREATE;
    let method = "POST";
    if (this.modeltitle == "Edit Coupon") {
      url = ConfigApi.URLS.COUPON.UPDATE + "/" + this.detail._id;
      method = "PUT";
    }
    let k = this.apiCallingService.callAPI(body, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }
  ngDestroy() {
    this.pId = "";
  }
}
