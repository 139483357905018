/* Config Class*/
export class ConfigApi {
  //public static SERVER_HOST = 'http://127.0.0.1:3647';
  public static SERVER_HOST = "https://atzid.com";
  // public static SERVER_HOST = "https://frekisprod.immerch.com";
  public static BASE_URL = ConfigApi.SERVER_HOST + "/api/"; // base url for server

  // URL CONSTANTS
  public static URLS: any = {
    LANGUAGE_URL: "../../assets/i18n/",
    LOGOUT_URL: ConfigApi.BASE_URL + "frekis/users/logout",
    REMOVE_ACCOUNT: ConfigApi.BASE_URL + "frekis/users/remove-account",
    GET_TRANSACTIONS: ConfigApi.BASE_URL + "frekis/stripe/transaction",
    GET_TRANSACTIONS_RECEIPT: ConfigApi.BASE_URL + "frekis/stripe/userReceipt",
    GET_TRANSACTIONS_CSV:
      ConfigApi.BASE_URL + "frekis/v3/cloudPanel/transactionHistory",
    GET_TRANSACTIONS_PDF:
      ConfigApi.BASE_URL + "frekis/v6/cloudPanel/pdf_report",
    GET_RIDE_HISTORY: ConfigApi.BASE_URL + "frekis/ride/listbyuserid",
    GET_RIDE_HISTORY_LIST:
      ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getOperatorRideHistory",
    LOCK_FALLBACK_HISTORY_LIST:
      ConfigApi.BASE_URL + "frekis/v5/lockFallback/fallback_list",
    GET_LEASE_HISTORY_LIST:
      ConfigApi.BASE_URL + "frekis/v3/cloudPanel/leaseHistory",
    GET_RIDE_HISTORY_DETAILS: ConfigApi.BASE_URL + "frekis/ride/getRideLatLong",
    MY_ASSET_RIDE_HISTORY:
      ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getMyAssetRideHistory",
    MY_ASSET_RIDE_HISTORY_DETAILS:
      ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getRideDetails",
    GET_PURCHASE_HISTORY:
      ConfigApi.BASE_URL + "frekis/v2/package/purchaseHistory",
    GET_NOTIFICATIONS: ConfigApi.BASE_URL + "frekis/notification/get",
    SUPPORT: {
      FAQ: ConfigApi.BASE_URL + "frekis/faq",
      OPERATOR_SUPPORT: ConfigApi.BASE_URL + "frekis/admin/operator_support",
      FEEDBACK: ConfigApi.BASE_URL + "frekis/feedback",
    },
    MY_PROFILE: {
      GET_DETAIL: ConfigApi.BASE_URL + "frekis/users/details",
      PRIVACY: ConfigApi.BASE_URL + "frekis/privacy",
      TERM_AND_CONDITIONS: ConfigApi.BASE_URL + "frekis/termsandcondition",
    },
    MY_ASSETS: ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getOperatorAssets",
    MY_ASSETS_FOR_LEASE:
      ConfigApi.BASE_URL + "frekis/v5/lock/owned_lock_for_lease",
    MY_LEASE_DELETE: ConfigApi.BASE_URL + "frekis/v2/package/restore_lock",
    MY_ASSETS_UPDATE_LOCK: ConfigApi.BASE_URL + "frekis/v2/lock/update",
    CREATE_ACCIDENT_REPORT:
      ConfigApi.BASE_URL + "frekis/accident_report/create",
    ADD_MEMEBR_FOR_LOCK: ConfigApi.BASE_URL + "frekis/v2/group/group_shared",
    REMOVE_MEMBER_FROM_LOCK: ConfigApi.BASE_URL + "frekis/v2/group/group_stash",
    SEARCH_MEMEBR_FOR_LOCK:
      ConfigApi.BASE_URL + "frekis/users/group_sharing_search",
    UPDATE_OPERATOR: ConfigApi.BASE_URL + "frekis/operator/update",
    UPDATE_CUSTOMER_REPLY_OPRATOR:
      ConfigApi.BASE_URL + "frekis/v5/customerReview/update",
    GET_LOCK_TYPE: ConfigApi.BASE_URL + "frekis/v2/lock/lock_type",
    RENT_PACKAGE: {
      LIST: ConfigApi.BASE_URL + "frekis/v2/package/getlist",
      GET_LICENSE_TYPE:
        ConfigApi.BASE_URL + "frekis/v6/license_documents/type_list",
      CREATE: ConfigApi.BASE_URL + "frekis/v2/package/create", //post
      UPDATE: ConfigApi.BASE_URL + "frekis/v2/package/update", //post
      DELETE: ConfigApi.BASE_URL + "frekis/v2/package/delete", //delete method
    },
    LEASE_PACKAGE: {
      LIST: ConfigApi.BASE_URL + "frekis/v2/leasPackage/get",
      CREATE: ConfigApi.BASE_URL + "frekis/v2/leasPackage", //post
      UPDATE: ConfigApi.BASE_URL + "frekis/v2/leasPackage/update", //post
      DELETE: ConfigApi.BASE_URL + "frekis/v2/leasPackage", //delete method
    },
    SALE_PACKAGE: {
      LIST: ConfigApi.BASE_URL + "frekis/v2/salePackage/get",
      CREATE: ConfigApi.BASE_URL + "frekis/v2/salePackage", //post
      UPDATE: ConfigApi.BASE_URL + "frekis/v2/salePackage/update", //post
      DELETE: ConfigApi.BASE_URL + "frekis/v2/salePackage", //delete method
    },
    ADVANCE_BOOKING: {
      CREATE: ConfigApi.BASE_URL + "frekis/v6/advance_booking/create_booking", //post
      // UPDATE: ConfigApi.BASE_URL + "frekis/v6/advance_booking/update", //put
      UPDATE: ConfigApi.BASE_URL + "frekis/v6/advance_booking/updateForCloud", //put
      LIST: ConfigApi.BASE_URL + "frekis/v6/advance_booking/list", //Get
      ADVANCE_LIST:
        ConfigApi.BASE_URL + "frekis/v6/advance_booking/advance_booking_list", //Get

      DELETE: ConfigApi.BASE_URL + "frekis/v6/advance_booking/delete", //delete
      CATEGORY_LIST: ConfigApi.BASE_URL + "frekis/v6/category/list",
      SUB_CATEGORY_LIST: ConfigApi.BASE_URL + "frekis/v6/sub_category/list",
      PACK_DETAIL: ConfigApi.BASE_URL + "frekis/v6/advance_booking/details/",
      NEXT_NUMBER_QUEUE:
        ConfigApi.BASE_URL + "frekis/v6/booking_queue/next_number_of_queue",
      MANUAL_NUMBER_QUEUE:
        ConfigApi.BASE_URL + "frekis/v6/booking_queue/manual_number_of_queue",
    },
    ALL_QUEUERS: {
      LIST: ConfigApi.BASE_URL + "frekis/v6/booking_queue/view_all_queuers",
    },
    UPCOMING_BOOKING: {
      LIST: ConfigApi.BASE_URL + "frekis/v6/advance_booking/user_purchase_list", //Get
      DELETE: ConfigApi.BASE_URL + "frekis/v6/advance_booking/cancel_purchase", //post
      UPDATE:
        ConfigApi.BASE_URL + "frekis/v6/advance_booking/refund_purchase_amount",
    },
    TRACK_ASSETS: {
      LIST: ConfigApi.BASE_URL + "frekis/v2/lock/lock_tracking",
      END_RIDE: ConfigApi.BASE_URL + "frekis/v7/ride/end", //post //"frekis/v5/ride/end", //old api
      END_PACK:
        ConfigApi.BASE_URL +
        "frekis/v6/advance_booking_ride/end_purchase_package",
    },
    ASSET_LOCK_LIST: ConfigApi.BASE_URL + "frekis/v2/lock/list",
    //shared ma country=IN&distance=1000&user_id&req_type=shared&latitude&longitute
    //owned ma sme without latlong
    PUT_IN_OPERATION: ConfigApi.BASE_URL + "frekis/operator/account_mode",
    SUBSCRIPTION: ConfigApi.BASE_URL + "frekis/v2/subscription",
    SUBSCRIPTION_PURCHASE:
      ConfigApi.BASE_URL + "frekis/v2/subscription/purchase",
    CANCEL_PLAN: ConfigApi.BASE_URL + "frekis/v2/subscription/cancel",
    DASHBOARD: {
      ALL_GET_PACKAGE:
        ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getPackages_count",
      GET_PACKAGE: ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getPackages",
      GET_SALE_PACKAGE: ConfigApi.BASE_URL + "frekis/v2/salePackage/get",
      GET_LEASE_PACKAGE: ConfigApi.BASE_URL + "frekis/v2/leasPackage/get",
      // GET_LEASE_PACKAGE: ConfigApi.BASE_URL + "frekis/v6/cloudPanel/book_advance_booking_list",
      GET_ADVANCE_LEASE_PACKAGE:
        ConfigApi.BASE_URL + "frekis/v6/advance_booking/package_list",

      GET_RIDES_COUNT:
        ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getRidesCount",
      GET_REVENUE: ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getRevenue",
      GET_RIDES: ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getRides",
      GET_LOCK_COUNT: ConfigApi.BASE_URL + "frekis/v3/cloudPanel/lockCount",
    },
    PAYMENT: {
      GET_CARDS: ConfigApi.BASE_URL + "frekis/stripe/getCardList",
      DELETE_CARD: ConfigApi.BASE_URL + "frekis/stripe/detachCard",
      ADD_CARD: ConfigApi.BASE_URL + "frekis/stripe/addCard",
      PAY_FOR_SUBSCRIPTION:
        ConfigApi.BASE_URL + "frekis/stripe/create_subscription_operator_plan",
      CREATE_INTENT: ConfigApi.BASE_URL + "frekis/stripe/paymentIntent",
      CONFIRM_INTENT: ConfigApi.BASE_URL + "frekis/stripe/confirmIntent",
      CAPTURE_INTENT:
        ConfigApi.BASE_URL + "frekis/stripe/pendingPaymentCapture",
      EPHEMERAL_KEYS: ConfigApi.BASE_URL + "frekis/stripe/ephemeral_keys",
    },
    WAIVER: {
      GET_WAIVER:
        ConfigApi.BASE_URL +
        "frekis/admin/waiverTermsandcondition/getTermsWithBrandByUserId",
      ADD: ConfigApi.BASE_URL + "frekis/admin/waiverTermsandcondition/add",
      UPDATE:
        ConfigApi.BASE_URL + "frekis/admin/waiverTermsandcondition/update",
    },
    COUPON: {
      COUPON_BY_PACKAGE:
        ConfigApi.BASE_URL + "frekis/v4/couponCode/getCouponByPackage",
      LIST: ConfigApi.BASE_URL + "frekis/v4/couponCode/get",
      CREATE: ConfigApi.BASE_URL + "frekis/v4/couponCode/create",
      UPDATE: ConfigApi.BASE_URL + "frekis/v4/couponCode/edit",
      CHECK: ConfigApi.BASE_URL + "frekis/admin/waiverTermsandcondition/update",
      DELETE: ConfigApi.BASE_URL + "frekis/v4/couponCode/delete",
      USED_LIST: ConfigApi.BASE_URL + "frekis/v4/couponCode/list",
    },
    SALE_COUPON: {
      CREATE: ConfigApi.BASE_URL + "frekis/v4/couponCode/createSaleCouponCode",
      UPDATE: ConfigApi.BASE_URL + "frekis/v4/couponCode/editSaleCouponCode",
    },
    LEASE_COUPON: {
      LEASELIST: ConfigApi.BASE_URL + "frekis/v4/couponCode/getLeaseCouponCode",
      CREATE: ConfigApi.BASE_URL + "frekis/v4/couponCode/createLeaseCouponCode",
      UPDATE: ConfigApi.BASE_URL + "frekis/v4/couponCode/editLeaseCouponCode",
    },
    ADVANCE_BOOK_COUPON: {
      LEASELIST: ConfigApi.BASE_URL + "frekis/v4/couponCode/getLeaseCouponCode",
      CREATE:
        ConfigApi.BASE_URL +
        "frekis/v4/couponCode/createAdvanceBookingCouponCode",
      UPDATE:
        ConfigApi.BASE_URL +
        "frekis/v4/couponCode/editAdvanceBookingCouponCode",
    },
    WAIVER_FORM: {
      LIST: ConfigApi.BASE_URL + "frekis/v6/cloudPanel/listByOperator",
      GET: ConfigApi.BASE_URL + "frekis/v3/smartwaiver/waiverDetail",
    },

    //Old
    SIGNUP_URL: ConfigApi.BASE_URL + "signup",
    CUSTOMER_REVIEW:
      ConfigApi.BASE_URL + "frekis/v5/customerReview/listTypeWise",
    CUSTOMER_LIST: ConfigApi.BASE_URL + "frekis/v6/operator/customers",
    SMTP: ConfigApi.BASE_URL + "frekis/v7/smtp/verify",
    FETCH_SMTP: ConfigApi.BASE_URL + "frekis/v7/smtp/get",
    DELETE_CUSTOMER: ConfigApi.BASE_URL + "frekis/v6/operator/customers/delete",
    IMPORT_EMAIL: ConfigApi.BASE_URL + "frekis/v6/operator/customers/import",
    EDIT_CUSTOMER: ConfigApi.BASE_URL + "frekis/v6/operator/customers/update",
    CONTENT_SEND_NEWSLETTER:
      ConfigApi.BASE_URL + "frekis/v7/newsletter/content",
    SEND_NEWSLETTER: ConfigApi.BASE_URL + "frekis/v7/newsletter/send",
    REGENERATE_IMAGE: ConfigApi.BASE_URL + "frekis/v7/newsletter/image",
    // CUSTOMER_REVIEW: ConfigApi.BASE_URL + "frekis/v6/cloudPanel/customer_review",
    FORGOT_URL: ConfigApi.BASE_URL + "forgotpassword",

    UPDATE_PROFILE: ConfigApi.BASE_URL + "users/update",
    CHANGE_PASSWORD: ConfigApi.BASE_URL + "users/change-password",
    USER_LIST: ConfigApi.BASE_URL + "users/list",
    CHANGE_STATUS: ConfigApi.BASE_URL + "admin/change-user-status",
    GET_DASHBOARD: ConfigApi.BASE_URL + "admin/dashboard",
    DELETE_USER: ConfigApi.BASE_URL + "admin/delete-user",

    TERM_CONDITIONS: {
      GET: ConfigApi.BASE_URL + "term-conditions/get",
      UPDATE: ConfigApi.BASE_URL + "term-conditions/update",
    },
    PRIVACY_POLICY: {
      GET: ConfigApi.BASE_URL + "privacy-policy/get",
      UPDATE: ConfigApi.BASE_URL + "privacy-policy/update",
    },
    HELP_FAQ: ConfigApi.BASE_URL + "faq",
    RULES_RAGULATIONS: ConfigApi.BASE_URL + "rules-ragulations",
    GEOFENCE: {
      LIST: ConfigApi.BASE_URL + "frekis/v5/geoFance/getBoundaryList",
      DETAILS: ConfigApi.BASE_URL + "frekis/v5/geoFance/details",
      CREATE: ConfigApi.BASE_URL + "frekis/v5/geoFance/create",
      UPDATE: ConfigApi.BASE_URL + "frekis/v5/geoFance/update",
      DELETE: ConfigApi.BASE_URL + "frekis/v5/geoFance/delete",
      BOUNDARY_DETAIL: ConfigApi.BASE_URL + "frekis/v5/geoFance/boundaryDetail",

      UPDATE_PARKING_SASSET:
        ConfigApi.BASE_URL + "frekis/v5/geoFance/updateParkingAreaAsset",
    },
    PLUDDA: {
      LIST: ConfigApi.BASE_URL + "frekis/v5/pludda/list",
      DETAILS: ConfigApi.BASE_URL + "frekis/v5/pludda/details",
      UPDATE: ConfigApi.BASE_URL + "frekis/v5/pludda/update",
      CHANGE_STATUS: ConfigApi.BASE_URL + "frekis/operator/update",
    },
    SECRET: {
      GENERATE:
        ConfigApi.BASE_URL + "frekis/v3/cloudPanel/generateOperatorSecret",
      GET: ConfigApi.BASE_URL + "frekis/v3/cloudPanel/getOperatorSecret",
    },
    PARKING_VIOLATION: {
      LIST: ConfigApi.BASE_URL + "frekis/v5/parkingViolation/list",
      GET: ConfigApi.BASE_URL + "frekis/v5/parkingViolation/detail/",
    },
    REQUEST_PAYMENT: {
      EMAIL_GET: ConfigApi.BASE_URL + "frekis/v6/users/",
      POST_REQUEST_PAYMENT:
        ConfigApi.BASE_URL + "frekis/v6/cloudPanel/request_payment/add",
      GET_REQUEST_PAYMENT:
        ConfigApi.BASE_URL + "frekis/v6/cloudPanel/request_payment/list",
      PUT_REQUEST_PAYMENT:
        ConfigApi.BASE_URL + "frekis/v6/cloudPanel/request_payment/update",
      DELETE_REQUEST_PAYMENT:
        ConfigApi.BASE_URL + "frekis/v6/cloudPanel/request_payment/delete/",
    },
    LOCK_ANALYTICS: {
      GET_LOCK_ANALYTICS_DATA:
        ConfigApi.BASE_URL + "frekis/v6/cloudPanel/lock_analytics/",
    },
    TIME_SLOT_DETAIL: {
      GET_SLOT_DETAIL_BY_ID:
        ConfigApi.BASE_URL + "frekis/v6/advance_booking/pack_detail_by_date",
      CREATE_SLOT: ConfigApi.BASE_URL + "frekis/v6/advance_booking/add_slot",
      UPDATE_DELETE_SLOT:
        ConfigApi.BASE_URL +
        "frekis/v6/advance_booking/time_slot_update_delete",
    },
    PAY_LATER_PAYMENTs: {
      GET_LIST:
        ConfigApi.BASE_URL + "frekis/v6/advance_booking/pay_later_user_list",
      UPDATE:
        ConfigApi.BASE_URL + "frekis/v6/advance_booking/update_pending_amount/",
    },
    PACKAGE_ADDON: {
      GET_LIST: ConfigApi.BASE_URL + "frekis/v7/package_addon/list_addon",
      UPLOAD_CSV: ConfigApi.BASE_URL + "frekis/v7/package_addon/upload_csv",
      PACKAGE_LIST:
        ConfigApi.BASE_URL + "frekis/v6/advance_booking/list_for_cloud_panel",
      CREATE: ConfigApi.BASE_URL + "frekis/v7/package_addon/create_addon",
      DELETE: ConfigApi.BASE_URL + "frekis/v7/package_addon/delete_addon",
      EDIT: ConfigApi.BASE_URL + "frekis/v7/package_addon/update_addon",
    },

    LICENSE: {
      CLOUD_PANEL_LIST:
        ConfigApi.BASE_URL + "frekis/v6/license_documents/cloud_panel_list",
      GET_LIST: ConfigApi.BASE_URL + "frekis/v6/license_documents",
      UPDATE:
        ConfigApi.BASE_URL +
        "frekis/v6/license_documents/update_license_status",
    },
  };
}
