import { PluddaDetailsComponent } from "./pludda/pludda-details/pludda-details.component";
import { PluddaComponent } from "./pludda/pludda.component";
import { MyAssetRideHistoryComponent } from "./my-asset-ride-history/my-asset-ride-history.component";
import { BoundaryComponent } from "./geofence-list/boundary/boundary.component";
import { GeofenceListComponent } from "./geofence-list/geofence-list.component";
import { GeofenceMapComponent } from "./geofence-map/geofence-map.component";
import { Routes } from "@angular/router";

import { TermConditionsComponent } from "./term-conditions/term-conditions.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProfileComponent } from "./profile/profile.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { FaqComponent } from "./faq/faq.component";

import { TransactionsComponent } from "./transactions/transactions.component";
import { CustomerReviewComponent } from "./customer-review/review.component";

import { RideHistoryComponent } from "./ride-history/ride-history.component";
import { PurchaseHistoryComponent } from "./purchase-history/purchase-history.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { OperatorSupportComponent } from "./operator-support/operator-support.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { MyAssetsComponent } from "./my-assets/my-assets.component";
import { DetailmyassetComponent } from "./my-assets/detailmyasset/detailmyasset.component";
import { BusinessProfileComponent } from "./business-profile/business-profile.component";
import { RentPackageComponent } from "./rent-package/rent-package.component";
import { LeasePackageComponent } from "./lease-package/lease-package.component";
import { SalePackageComponent } from "./sale-package/sale-package.component";
import { TrackAssetsComponent } from "./track-assets/track-assets.component";
import { PaymentMethodComponent } from "./payment-method/payment-method.component";
import { DetailsComponent } from "./ride-history/details/details.component";
import { RideDetailsComponent } from "./my-asset-ride-history/ride-details/ride-details.component";
import { OperationComponent } from "./operation/operation.component";
import { SubscriptionComponent } from "./subscription/subscription.component";
import { HomeComponent } from "./home/home.component";
import { AddPaymentComponent } from "./add-payment/add-payment.component";
import { WaiverComponent } from "./waiver/waiver.component";
import { CouponsComponent } from "./coupons/coupons.component";
import { UsedCouponsComponent } from "./used-coupons/used-coupons.component";
import { WaiverFormsComponent } from "./waiver-forms/waiver-forms.component";
import { ExternalApiComponent } from "./external-api/external-api.component";
import { FrekisTermComponent } from "./frekis-term/frekis-term.component";
import { SharekayakTermComponent } from "./sharekayak-term/sharekayak-term.component";
import { OdeTermComponent } from "./ode-term/ode-term.component";
import { ParkingViolationComponent } from "./parking-violation/parking-violation.component";
import { ParkingViolationDetailComponent } from "./parking-violation/parking-violation-detail/parking-violation-detail.component";
import { SaleCouponsComponent } from "./sale-coupons/sale-coupons.component";
import { LeaseCouponsComponent } from "./lease-coupons/lease-coupons.component";
import { LeaseHistoryComponent } from "./lease-history/lease-history.component";
import { LockFallbackComponent } from "./lock-fallback/lock-fallback.component";
import { RequestPaymentComponent } from "./request-payment/request-payment.component";
import { BusinessAnalyticsComponent } from "./my-assets/business-analytics/business-analytics.component";
import { AdvanceBookingComponent } from "./advance-booking/advance-booking.component";
import { TimeSlotComponent } from "./time-slot/time-slot.component";
import { UpcomingBookingComponent } from "./upcoming-booking/upcoming-booking.component";
import { AdvanceBookCouponsComponent } from "./advance-book-coupons/advance-book-coupons.component";
import { MapComponent } from "./map/map.component";
import { AllQueuersComponent } from "./all-queuers/all-queuers.component";
import { PayLaterPaymentComponent } from "./pay-later-payment/pay-later-payment.component";
import { PackageAddonComponent } from "./package-addon/package-addon.component";
import { LicenseComponent } from "./license/license.component";
import { SmtpFormComponent } from "./news-letter/smtp-form/smtp-form.component";
import { GoogleFormComponent } from "./news-letter/google-form/google-form.component";
import { NewsLetterComponent } from "./news-letter/news-letter.component";
import { NewsletterGuard } from "../shared/newsletterGaurd/newsletter-guard.guard";
export const AdminRoutingModule: Routes = [
  {
    path: "",
    children: [
      {
        path: "home",
        component: HomeComponent,
        data: {
          title: "", //Dashboard
        },
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        data: {
          title: "Assets",
          breadcrumb: [
            { title: "Home", url: "/user/home" },
            { title: "Assets", url: "" },
          ],
        },
      },
      {
        path: "geofence",
        component: GeofenceListComponent,
        data: {
          title: "Geofence",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Geofence", url: "" },
          ],
        },
      },
      {
        path: "geofence-map/:boundaryId",
        component: GeofenceMapComponent,
        data: {
          title: "Geofence",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Geofence Map", url: "/geofence-map/:boundaryId" },
          ],
        },
      },
      {
        path: "add-geofence-boundary",
        component: BoundaryComponent,
        data: {
          title: "Geofence",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Geofence", url: "user/geofence" },
            { title: "Add Geofence Boundary", url: "/add-geofence-boundary" },
          ],
        },
      },

      {
        path: "geofence/:id",
        component: BoundaryComponent,
        data: {
          title: "Geofence",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Geofence", url: "user/geofence" },
            { title: "Geofence", url: "/geofence/:id" },
          ],
        },
      },
      {
        path: "operation",
        component: OperationComponent,
        data: {
          title: "Put in Operation",
          subtitle:
            "Your payment will be confirmed and all your assets will be public",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Operation", url: "/operation" },
          ],
        },
      },
      {
        path: "operation/:paid",
        component: OperationComponent,
        data: {
          title: "Put in Operation",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Operation", url: "/operation/:paid" },
          ],
        },
      },
      {
        path: "business-profile",
        component: BusinessProfileComponent,
        data: {
          title: "Business profile",
          subtitle:
            "You will need to add some basic detail about your company to get started",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Business Profile", url: "/business-profile" },
          ],
        },
      },
      {
        path: "my-assets",
        component: MyAssetsComponent,
        data: {
          title: "My assets",
          subtitle:
            "Assets are bikes/scooters/boats which are embedded with scannable locks",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "My Assets", url: "/my-assets" },
          ],
        },
      },
      {
        path: "my-assets/detail/:id",
        component: DetailmyassetComponent,
        data: {
          title: "Asset detail",
          breadcrumb: [
            { title: "My Assets", url: "/my-assets" },
            { title: "Asset Detail", url: "" },
          ],
        },
      },
      {
        path: "business-analytics/:id",
        component: BusinessAnalyticsComponent,
        data: {
          title: "Business analytics",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "My Assets", url: "user/my-assets" },
            { title: "Business Analytics", url: "" },
          ],
        },
      },
      {
        path: "add-payment",
        component: AddPaymentComponent,
        data: {
          title: "Add Payment",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Add your payment account", url: "/add-payment" },
          ],
        },
      },
      {
        path: "rent-package",
        component: RentPackageComponent,
        data: {
          title: "Rent package",
          subtitle:
            "Bases on package bike will shared with user.You will earn from sharing",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Rent Package", url: "/rent-package" },
          ],
        },
      },
      {
        path: "request_payment",
        component: RequestPaymentComponent,
        data: {
          title: "Request Payment",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Request Payment", url: "/request_payment" },
          ],
        },
      },
      {
        path: "pay-later-payment",
        component: PayLaterPaymentComponent,
        data: {
          title: "Pay Later Payments",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Pay Later Payments", url: "/pay-later-payment" },
          ],
        },
      },
      {
        path: "book_package",
        component: LeasePackageComponent,
        data: {
          title: "Book Package",
          subtitle:
            "Customer will have asset access as an onwer for good duration.",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Book Package", url: "/book_package" },
          ],
        },
      },

      {
        path: "advance_booking",
        component: AdvanceBookingComponent,
        data: {
          title: "Advance Booking",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Advance Booking", url: "/advance_booking" },
          ],
        },
      },
      {
        path: "package_addon",
        component: PackageAddonComponent,
        data: {
          title: "Package Addon",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Package Addon" },
          ],
        },
      },
      {
        path: "location_search",
        component: MapComponent,
        data: {
          title: "Location",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Location" },
          ],
        },
      },
      {
        path: "time-slot/:id",
        component: TimeSlotComponent,
        data: {
          title: "TimeSlot",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Advance Booking", url: "user/advance_booking" },
            { title: "TimeSlot", url: "" },
          ],
        },
      },
      {
        path: "all-queuers/:id",
        component: AllQueuersComponent,
        data: {
          title: "All Queuers",
          urls: [{ title: "Home", url: "user/home" }, { title: "All Queuers" }],
        },
      },
      {
        path: "sale-package",
        component: SalePackageComponent,
        data: {
          title: "Sale Package",
          subtitle:
            "Asset will transfer to user account.You are all access to asset will be removed",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Sale Package", url: "sale_package" },
          ],
        },
      },
      {
        path: "users-booking",
        component: UpcomingBookingComponent,
        data: {
          title: "Users Booking List",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Users Booking List", url: "" },
          ],
        },
      },
      {
        path: "waiver",
        component: WaiverComponent,
        data: {
          title: "Waiver Term and conditions",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Waiver Term and conditions", url: "" },
          ],
        },
      },
      {
        path: "parking-violation/detail/:id",
        component: ParkingViolationDetailComponent,
        data: {
          title: "Parking Info",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Parking Violation", url: "user/parking-violation" },
            { title: "Parking Info" },
          ],
        },
      },

      {
        path: "external-api",
        component: ExternalApiComponent,
        data: {
          title: "External Apis",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "External Apis", url: "" },
          ],
        },
      },
      {
        path: "parking-violation",
        component: ParkingViolationComponent,
        data: {
          title: "Parking Violation",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Parking Violation", url: "" },
          ],
        },
      },
      {
        path: "rent-package/coupons",
        component: CouponsComponent,
        data: {
          title: "Package Coupons",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Rent Package", url: "user/rent-package" },
            { title: "Coupons", url: "" },
          ],
        },
      },
      {
        path: "sale-package/sale-coupons",
        component: SaleCouponsComponent,
        data: {
          title: "Package Coupons",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Sale Package", url: "user/sale-package" },
            { title: "Coupons", url: "" },
          ],
        },
      },
      {
        path: "book_package/book_coupons",
        component: LeaseCouponsComponent,
        data: {
          title: "Package Coupons",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Book Package", url: "user/book_package" },
            { title: "Coupons", url: "" },
          ],
        },
      },
      {
        path: "advance_booking/advance_book_coupons",
        component: AdvanceBookCouponsComponent,
        data: {
          title: "Package Coupons",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Advance Booking", url: "user/advance_booking" },
            { title: "Coupons", url: "" },
          ],
        },
      },
      {
        path: "used-coupons",
        component: UsedCouponsComponent,
        data: {
          title: "Used Coupon codes",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Used Coupon codes", url: "" },
          ],
        },
      },
      {
        path: "waiver-forms",
        component: WaiverFormsComponent,
        data: {
          title: "Waiver forms (Customers)",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Waiver forms (Customers)", url: "" },
          ],
        },
      },
      {
        path: "track-assets",
        component: TrackAssetsComponent,
        data: {
          title: "Track assets",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Track assets", url: "" },
          ],
        },
      },
      {
        path: "purchase-history",
        component: PurchaseHistoryComponent,
        data: {
          title: "Purchase history",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Purchase history", url: "" },
          ],
        },
      },
      {
        path: "my-ride-history",
        component: RideHistoryComponent,
        data: {
          title: "My Ride History",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "My Ride History", url: "" },
          ],
        },
      },
      {
        path: "my-ride-history/detail/:id",
        component: DetailsComponent,
        data: {
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "My Ride History", url: "user/my-ride-history" },
            { title: "My Ride Info" },
          ],
        },
      },
      {
        path: "my-asset-ride-history",
        component: MyAssetRideHistoryComponent,
        data: {
          title: "My Asset Ride History",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "My Asset Ride History", url: "" },
          ],
        },
      },
      {
        path: "my-asset-ride-history/detail/:id",
        component: RideDetailsComponent,
        data: {
          title: "My Asset Ride History",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            {
              title: "My Asset Ride History",
              url: "user/my-asset-ride-history",
            },
            { title: "My Asset Ride Info" },
          ],
        },
      },
      {
        path: "my-book-history",
        component: LeaseHistoryComponent,
        data: {
          title: "My Book History",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "My Book History", url: "" },
          ],
        },
      },
      {
        path: "transactions",
        component: TransactionsComponent,
        data: {
          title: "Transactions",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Transactions", url: "" },
          ],
        },
      },
      {
        path: "pludda",
        component: PluddaComponent,
        data: {
          title: "Pludda",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Pludda", url: "" },
          ],
        },
      },
      {
        path: "pludda-details/:id",
        component: PluddaDetailsComponent,
        data: {
          title: "Pludda Details",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Pludda", url: "user/pludda" },
            { title: "Pludda Info" },
          ],
        },
      },
      {
        path: "lock-fallback",
        component: LockFallbackComponent,
        data: {
          title: "Lock Fallback History",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Lock Fallback History", url: "" },
          ],
        },
      },
      {
        path: "customer-review",
        component: CustomerReviewComponent,
        data: {
          title: "Customer Review",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Customer Review", url: "" },
          ],
        },
      },
      {
        path: "notifications",
        component: NotificationsComponent,
        data: {
          title: "Notifications",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Notifications", url: "" },
          ],
        },
      },
      {
        path: "profile",
        component: ProfileComponent,
        data: {
          title: "Profile setting",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Profile setting", url: "" },
          ],
        },
      },

      {
        path: "term-conditions",
        component: TermConditionsComponent,
        data: {
          title: "Term & conditions",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Term & conditions", url: "" },
          ],
        },
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
        data: {
          title: "Privacy & Policy",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Privacy & Policy", url: "" },
          ],
        },
      },
      {
        path: "payment-method",
        component: PaymentMethodComponent,
        data: {
          title: "Payments",
          subtitle: "Here you can manage your card for make payments",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Payments", url: "" },
          ],
        },
      },
      {
        path: "payment-method/:pay",
        component: PaymentMethodComponent,
        data: {
          title: "Payments",
          subtitle: "Here you can manage your card for make payments",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Payments" },
          ],
        },
      },
      {
        path: "subscription",
        component: SubscriptionComponent,
        data: {
          title: "Subscription",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Subscription", url: "" },
          ],
        },
      },
      {
        path: "support/faq",
        component: FaqComponent,
        data: {
          title: "Help & FAQ",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Help & FAQ", url: "" },
          ],
        },
      },
      {
        path: "support/operator-support",
        component: OperatorSupportComponent,
        data: {
          title: "Operator support",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Operator support", url: "" },
          ],
        },
      },

      {
        path: "support/feedback",
        component: FeedbackComponent,
        data: {
          title: "Feedback",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Feedback", url: "" },
          ],
        },
      },
      {
        path: "waiver-terms/frekis",
        component: FrekisTermComponent,
        data: {
          title: "Frekis",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Frekis Waiver", url: "" },
          ],
        },
      },
      {
        path: "waiver-terms/sharekayak",
        component: SharekayakTermComponent,
        data: {
          title: "Sharekayak",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Sharekayak Waiver", url: "" },
          ],
        },
      },
      {
        path: "waiver-terms/shrvee",
        component: OdeTermComponent,
        data: {
          title: "Shrvee Services",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Shrvee Waiver", url: "" },
          ],
        },
      },
      {
        path: "license",
        component: LicenseComponent,
        data: {
          title: "License",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "License", url: "" },
          ],
        },
      },
      {
        path: "news-letter",
        component: NewsLetterComponent,
        data: {
          title: "Newsletter",
          breadcrumb: [
            { title: "Home", url: "user/home" },
            { title: "Newsletter", url: "" },
          ],
        },
      },
      {
        path: "news-letter/smtp",
        component: SmtpFormComponent,
        canActivate: [NewsletterGuard],
        data: { guardCheck: true },
      },
      {
        path: "news-letter/google",
        component: GoogleFormComponent,
        canActivate: [NewsletterGuard],
        data: { guardCheck: true },
      },
    ],
  },
];
