import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { Globals } from "../../common/global";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-advance-book-coupons",
  templateUrl: "./advance-book-coupons.component.html",
  styleUrls: ["./advance-book-coupons.component.css"],
})
export class AdvanceBookCouponsComponent implements OnInit {
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  today: any = moment(new Date()).format("YYYY-MM-DD");

  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  packages = [];
  pattern = /(\w+)$/;
  pId: any;

  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.pId = params["pId"];
    });

    this.getList();
    this.getPackages();
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);
    this.detail.advance_booking_package_id =
      this.detail?.advance_booking_package_id?._id;
    this.detail.expire_date = moment(
      this.detail.expire_date ?? new Date()
    ).format("YYYY-MM-DD");
    if (Object.keys(data).length == 0) {
      this.detail.isMultipleUse = false;
      this.detail.type = "hour";
      this.detail.time = 1;
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }

  getList() {
    let user_id = this.Globals.getUser()._id;
    let operator_id = this.Globals.getUser()._id;

    let packageType = "advance_booking";

    let payload = {
      operator_id: operator_id,
      coupon_for: packageType,
      package_id: this.pId,
    };
    this.apiCallingService
      .callAPI(payload, "POST", ConfigApi.URLS.COUPON.COUPON_BY_PACKAGE)
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          this.totalcount = 2;
        }
      });
  }
  getPackages() {
    let user_id = this.Globals.getUser()._id;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.DASHBOARD.GET_ADVANCE_LEASE_PACKAGE +
          "?operator_id=" +
          user_id
      )
      .subscribe((res) => {
        this.packages = res.data;
        console.log(
          "🚀 ~ file: lease-coupons.component.ts:87 ~ LeaseCouponsComponent ~ .subscribe ~ res:",
          this.packages
        );
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getList();
  }
  delete(): void {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.COUPON.DELETE + "/" + this.detail._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }
  onSubmit(f: any) {
    const body = new HttpParams()
      .set("operator_id", this.Globals.getUser()._id)
      .set("advance_booking_package_id", this.pId)
      .set("couponCode", f.value.couponCode)
      .set("discountPercent", f.value.discountPercent)
      .set("isMultipleUse", f.value.isMultipleUse)
      .set("expire_date", f.value.expire_date)
      .set("limit", f.value.limit);

    let url = ConfigApi.URLS.ADVANCE_BOOK_COUPON.CREATE;
    let method = "POST";
    if (this.modeltitle == "Edit Coupon") {
      url = ConfigApi.URLS.ADVANCE_BOOK_COUPON.UPDATE + "/" + this.detail._id;
      method = "PUT";
    }
    this.apiCallingService.callAPI(body, method, url).subscribe(
      (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        this.toastr.error(err.error.msg, "Error");
      }
    );
  }
}
