<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <!-- User Profile-->
    <!-- <li>
            <div class="user-profile d-flex no-block m-t-20">
                <div class="user-pic">
                    <img src="assets/images/users/1.jpg" alt="users" class="rounded-circle" width="40" />
                </div>
                <div class="dropdown user-content hide-menu m-l-10" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle href="javascript:void(0)" class="dropdown-toggle" id="Userdd" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <h5 class="m-b-0 user-name font-medium">Steave Jobs
                            <i class="fa fa-angle-down"></i>
                        </h5>
                        <span class="op-5 user-email">steve@gmail.com</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="Userdd" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                    </div>
                </div>
            </div>
        </li> -->
    <!-- <li class="p-15 m-t-10">
            <a href="javascript:void(0)" class="btn btn-block create-btn text-white no-block d-flex align-items-center">
                <i class="fa fa-plus-square"></i>
                <span class="hide-menu m-l-5">Create New</span>
            </a>
        </li> -->
    <!-- First level menu -->
    <li
      class="sidebar-item"
      [class.active]="showMenu === sidebarnavItem.title"
      *ngFor="let sidebarnavItem of sidebarnavItems"
      [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
    >
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{ sidebarnavItem.title | translate }}</span>
      </div>
      <a
        class="sidebar-link waves-effect waves-dark"
        [routerLink]="sidebarnavItem.path"
        [ngClass]="[sidebarnavItem.class]"
        *ngIf="!sidebarnavItem.extralink && checkToshow(sidebarnavItem.title)"
        (click)="addExpandClass(sidebarnavItem.title)"
        [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
      >
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{ sidebarnavItem.title | translate }}</span>
      </a>
      <!-- Second level menu -->
      <ul
        aria-expanded="false"
        class="collapse first-level"
        *ngIf="sidebarnavItem.submenu.length > 0"
        [ngClass]="{ in: showMenu === sidebarnavItem.title }"
      >
        <li
          class="sidebar-item"
          *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
          [class.active]="showSubMenu === sidebarnavSubItem.title"
          [routerLinkActive]="
            sidebarnavSubItem.submenu.length > 0 ? '' : 'active'
          "
        >
          <a
            class="sidebar-link"
            [routerLink]="
              sidebarnavSubItem.submenu.length > 0
                ? null
                : [sidebarnavSubItem.path]
            "
            [routerLinkActive]="
              sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'
            "
            [ngClass]="[sidebarnavSubItem.class]"
            *ngIf="!sidebarnavSubItem.extralink"
            (click)="addActiveClass(sidebarnavSubItem.title)"
          >
            <i [ngClass]="[sidebarnavSubItem.icon]"></i>
            <span class="hide-menu">{{
              sidebarnavSubItem.title | translate
            }}</span>
          </a>
          <!-- Third level menu -->
          <ul
            aria-expanded="false"
            class="collapse Second-level"
            *ngIf="sidebarnavSubItem.submenu.length > 0"
            [ngClass]="{ in: showSubMenu === sidebarnavSubItem.title }"
          >
            <li
              class="sidebar-item"
              *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
              routerLinkActive="active"
              [ngClass]="[sidebarnavSubsubItem.class]"
            >
              <a
                class="sidebar-link"
                [routerLink]="[sidebarnavSubsubItem.path]"
                *ngIf="!sidebarnavSubsubItem.extralink"
                [routerLinkActive]="
                  sidebarnavSubsubItem.submenu.length > 0
                    ? ''
                    : 'router-link-active'
                "
              >
                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                <span class="hide-menu">{{
                  sidebarnavSubsubItem.title | translate
                }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>
