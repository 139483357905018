<div class="back-button-container mb-3">
  <button
    (click)="router.navigate(['/user/news-letter'])"
    class="btn-primary back-btn"
    ngbTooltip="Back to Newsletter"
    placement="top"
  >
    <i class="fas fa-arrow-left"></i>
  </button>
</div>
<div class="container">
  <div class="smtp-form-section">
    <h2 class="title">SMTP Details - Sending Emails</h2>

    <form #smtpForm="ngForm" (ngSubmit)="onSubmit(smtpForm)" novalidate>
      <div class="form-group">
        <label>Sender Name</label>
        <input
          type="text"
          class="form-control"
          name="senderName"
          [(ngModel)]="formData.senderName"
          placeholder="Enter Name"
          required
          #senderName="ngModel"
        />
        <div
          *ngIf="senderName.invalid && senderName.touched"
          class="error-message"
        >
          Sender Name is required.
        </div>
      </div>

      <div class="form-group">
        <label>Email Address</label>
        <div class="email-group">
          <input
            type="email"
            class="form-control"
            name="email"
            [(ngModel)]="formData.email"
            placeholder="Enter Email"
            required
            email
            #email="ngModel"
          />
          <div *ngIf="email.invalid && email.touched" class="error-message">
            <div *ngIf="email.errors?.required">Email is required.</div>
            <div *ngIf="email.errors?.email">
              Please enter a valid email address.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Password</label>
        <div class="password-group">
          <input
            [type]="showPassword ? 'text' : 'password'"
            class="form-control"
            name="password"
            [(ngModel)]="formData.password"
            placeholder="Enter Password"
            required
            #password="ngModel"
          />
          <div
            *ngIf="password.invalid && password.touched"
            class="error-message"
          >
            Password is required.
          </div>
          <button
            type="button"
            class="password-toggle"
            (click)="togglePassword()"
          >
            <i
              class="fas"
              [class.fa-eye]="!showPassword"
              [class.fa-eye-slash]="showPassword"
            ></i>
          </button>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-7">
          <label>SMTP Host</label>
          <input
            type="text"
            class="form-control"
            name="smtpHost"
            [(ngModel)]="formData.smtpHost"
            placeholder="Enter SMTP Host"
            required
            #smtpHost="ngModel"
          />
          <div
            *ngIf="smtpHost.invalid && smtpHost.touched"
            class="error-message"
          >
            SMTP Host is required.
          </div>
        </div>
        <div class="form-group col-md-5">
          <label>SMTP Port</label>
          <input
            type="number"
            class="form-control"
            name="smtpPort"
            [(ngModel)]="formData.smtpPort"
            placeholder="465"
            required
            #smtpPort="ngModel"
          />
          <div
            *ngIf="smtpPort.invalid && smtpPort.touched"
            class="error-message"
          >
            SMTP Port is required.
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Encryption</label>
        <div class="encryption-options">
          <label class="radio-label">
            <input
              type="radio"
              name="encryption"
              value="ssl"
              [(ngModel)]="formData.encryption"
              required
              #encryption="ngModel"
            />
            SSL
          </label>
          <label class="radio-label">
            <input
              type="radio"
              name="encryption"
              value="tls"
              [(ngModel)]="formData.encryption"
            />
            TLS
          </label>
          <label class="radio-label">
            <input
              type="radio"
              name="encryption"
              value="None"
              [(ngModel)]="formData.encryption"
            />
            None
          </label>
        </div>
        <div
          *ngIf="encryption.invalid && smtpForm.submitted"
          class="error-message"
        >
          Encryption is required.
        </div>
      </div>

      <div *ngIf="isShowChangeButton" class="mt-4">
        <button
          class="change-smtp-btn p-2"
          type="button"
          (click)="changeToGoogle()"
        >
          <i class="fas fa fa-random mr-1" aria-hidden="true"></i>
          Change SMTP to Google
        </button>
      </div>

      <div class="button-group">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="resetForm(smtpForm)"
        >
          Reset
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="smtpForm.invalid"
        >
          Connect
        </button>
      </div>
    </form>
  </div>

  <!-- <div class="instruction-container">
    <h4 class="instruction-title">
      Instructions to connect your email account through the SMTP Method
    </h4>

    <div class="instructions">
      <ol>
        <li>Make sure SMTP & IMAP are enabled on your email account.</li>
        <li>
          If you’re using a regular password, ensure that 2FA (Multi-factor
          authentication) is disabled on your email account.
        </li>
        <li>
          If your ESP supports an app password, use it instead of a regular
          password.
        </li>
        <li>
          Use correct details like SMTP/IMAP Host, Port & Encryption. Confirm it
          with your email provider.
        </li>
        <li>
          If you still need assistance connecting your SMTP email to
          MailOptimal, initiate a chat with us.
        </li>
        <li>
          Use correct SMTP details like host & port to connect your email
          account.
          <a href="#">Click here</a> to know SMTP details for popular email
          providers.
        </li>
      </ol>
    </div>
  </div> -->
</div>
