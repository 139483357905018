import { Component, OnInit, ViewChild, Directive } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";

import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { NgForm, AbstractControl } from "@angular/forms";
import { Globals } from "../../common/global";
import { Router } from "@angular/router";

@Component({
  selector: "app-rent-package",
  templateUrl: "./rent-package.component.html",
  styleUrls: ["./rent-package.component.css"],
})
export class RentPackageComponent implements OnInit {
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  operatorDetails: any;
  licenseTypelist: any[] = [];
  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  currency: any = localStorage.getItem("CUR");
  isWaiverDisplay: boolean = true;
  request_licence: boolean = false;
  enable_extra_fee: boolean = true;
  enable_video: boolean = true;

  locktypes = [];
  timings = ["Minute", "Hour", "Day", "Week", "Month"];
  maxValue: number = 1;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals
  ) {
    this.operatorDetails = this.Globals.getOperatorDetails();
  }

  ngOnInit(): void {
    this.getList();
    this.getLockTypes();
    this.getLicenseType();
  }

  changeTiming(e: any) {
    if (e.target.value == "Minute") {
      this.maxValue = 60;
    } else if (e.target.value == "Hour") {
      this.maxValue = 24;
    } else if (e.target.value == "Day") {
      this.maxValue = 365;
    } else if (e.target.value == "Week") {
      this.maxValue = 52;
    } else if (e.target.value == "Month") {
      this.maxValue = 12;
    }
  }
  onLicenseDisplayChange(request_licence: boolean) {
    if (!request_licence) {
      this.detail.license_type = this.licenseTypelist[0];
    }
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);
    if (this.modeltitle == "Create Package") {
      if (!this.detail.video_link) {
        this.detail.video_link = "https://atz.ai/safety/";
      }
    }
    if (Object.keys(data).length == 0) {
      this.detail.isExclusive = false;
      this.detail.type = this.locktypes[0];
      this.detail.vat = 6.0;
      this.detail.duration = 1;
      this.detail.isWaiverDisplay = false;
      this.detail.request_licence = false;
      this.detail.license_type = this.licenseTypelist[0];
      this.detail.enable_video = false;
      this.detail.enable_extra_fee = false;
    } else {
      if (this.detail.timing == "Minute") {
        this.maxValue = 60;
      } else if (this.detail.timing == "Hour") {
        this.maxValue = 24;
      } else if (this.detail.timing == "Day") {
        this.maxValue = 365;
      } else if (this.detail.timing == "Week") {
        this.maxValue = 52;
      } else if (this.detail.timing == "Month") {
        this.maxValue = 12;
      }
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  getList() {
    let user_id = this.Globals.getUser()._id;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.RENT_PACKAGE.LIST + "?user_id=" + user_id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          this.totalcount = res.data.count;
        }
      });
  }
  getLicenseType() {
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.RENT_PACKAGE.GET_LICENSE_TYPE)
      .subscribe((res) => {
        this.licenseTypelist = res.data;
      });
  }
  getLockTypes() {
    let user_id = this.Globals.getUser()._id;
    let query = "?user_id=" + user_id;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_LOCK_TYPE + query)
      .subscribe((res) => {
        this.locktypes = res.data;
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getList();
  }

  delete(): void {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.RENT_PACKAGE.DELETE + "/" + this.detail._id
      )
      .subscribe({
        next: (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success("Successfully deleted");
            this.closeBtnClick();
            this.ngOnInit();
          }
        },
        error: (e) => {
          this.toastr.error(e.error.msg);
        },
        complete: () => console.info("complete"),
      });
  }
  onSubmit(f: any) {
    let enable_extra_fee =
      f.value.timing === "Week" || f.value.timing === "Month"
        ? this.detail.enable_extra_fee
        : false;

    let body = new HttpParams()
      .set("user_id", this.Globals.getUser()._id)
      .set("timing", f.value.timing)
      .set("price", f.value.price)
      .set("name", "Per " + f.value.timing)
      .set("base_price", f.value.base_price)
      .set("pkg_desc", f.value.pkg_desc || "")
      .set("duration", f.value.duration)
      .set("enable_extra_fee", enable_extra_fee.toString())
      .set("vat", f.value.vat)
      .set("isExclusive", f.value.isExclusive.toString())
      .set("isWaiverDisplay", this.detail.isWaiverDisplay.toString())
      .set("request_licence", this.detail.request_licence.toString())
      .set("enable_video", (this.detail.enable_video || false).toString())
      .set("video_link", f.value.video_link || "https://atz.ai/safety/")
      .set(
        "security_deposit",
        f.value.timing === "Minute" || f.value.timing === "Hour"
          ? f.value.security_deposit
          : "0"
      )
      .set("type", f.value.type);
    if (this.detail.request_licence) {
      body = body.set("license_type", f.value.license_type);
    }

    if (enable_extra_fee) {
      body = body
        .set("free_minutes", f.value.free_minutes)
        .set("package_name", f.value.package_name)
        .set("extra_fee", f.value.extra_fee);
    } else {
      body = body
        .set("free_minutes", f.value.free_minutes || "")
        .set("package_name", f.value.package_name || "")
        .set("extra_fee", f.value.extra_fee || "");
    }

    let url = ConfigApi.URLS.RENT_PACKAGE.CREATE;
    let method = "POST";
    if (this.modeltitle == "Edit Package") {
      url = ConfigApi.URLS.RENT_PACKAGE.UPDATE + "/" + this.detail._id;
      method = "POST";
    }
    this.apiCallingService.callAPI(body, method, url).subscribe({
      next: (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        }
      },
      error: (e) => {
        this.toastr.error(e.error.msg);
      },
      complete: () => console.info("complete"),
    });
  }

  viewCouponList(data: any) {
    const packageId = data._id;
    this.router.navigate(["/user/rent-package/coupons"], {
      queryParams: { pId: packageId },
    });
  }
}
