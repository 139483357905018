import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NewsletterGuard implements CanActivate {
  private static isProviderSelected = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const currentValue = NewsletterGuard.isProviderSelected.getValue();

    if (currentValue) {
      return true;
    }

    this.router.navigate(["/user/news-letter"]);
    return false;
  }

  static setProviderSelected(value: boolean) {
    NewsletterGuard.isProviderSelected.next(value);
  }

  static resetProviderSelected() {
    NewsletterGuard.isProviderSelected.next(false);
  }

  static getCurrentState(): boolean {
    return NewsletterGuard.isProviderSelected.getValue();
  }
}
