import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiCallingService } from "../../services/api-calling.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigApi } from "../../config-api";
import { HttpParams } from "@angular/common/http";
import { NewsletterGuard } from "../../shared/newsletterGaurd/newsletter-guard.guard";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface Provider {
  key: string;
  icon: string;
  name: string;
  description: string;
}
interface CustomerDetail {
  _id: string;
  user_name: string;
  email: string;
}

interface UserDetail {
  _id: string;
}

@Component({
  selector: "app-news-letter",
  templateUrl: "./news-letter.component.html",
  styleUrls: ["./news-letter.component.css"],
})
export class NewsLetterComponent implements OnInit {
  @ViewChild("imageModal") imageModal: any;
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: [
      "heading",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "undo",
      "redo",
    ],
  };
  FormDetails: { title: string; subject: string; content: string } = {
    title: "",
    subject: "",
    content: "",
  };
  providers: Provider[] = [
    {
      key: "google",
      icon: "assets/images/Google_Icons.webp",
      name: "Google",
      description: "Gmail & Google Workspace",
    },
    {
      key: "smtp",
      icon: "assets/images/smtp.png",
      name: "SMTP",
      description: "Any other Email Service provider account",
    },
  ];
  public editorContent: string = "";
  public showEditor: boolean = false;
  currentProvider: Provider | undefined;
  isSmtpProvider: boolean = false;
  isGoogleProvider: boolean = false;
  isShowOption: boolean = false;
  userDetail: UserDetail = { _id: "" };
  customerList: CustomerDetail[] = [];
  data: [] = [];
  page = 0;
  pageSize = 10;
  totalcount: number = 0;
  generatedImageUrl: string | null = null;
  isChangeEditor: boolean = false;
  modeltitle: string = "";
  detail: any;
  htmlData: string = "";
  multilineError: boolean = false;
  fetchSmtp: { type: string } = { type: "" };

  set FormDetailsTitle(value: string) {
    this.FormDetails.title = value;
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    let data: any = localStorage.getItem("Auth-user");
    this.userDetail = JSON.parse(data);
    NewsletterGuard.resetProviderSelected();
    this.getList();

    this.route.paramMap.subscribe(() => {
      this.setCurrentProvider();
    });
  }

  loadPage(page: number) {
    if (page) {
      this.page = page;
      this.getList();
    }
  }

  setCurrentProvider(): void {
    const url = this.router.url;
    const parts = url.split("/");
    const providerKey = parts[parts.length - 1];

    this.currentProvider = this.providers.find(
      (provider: any) => provider.key === providerKey
    );

    this.isSmtpProvider = providerKey === "smtp";
    this.isGoogleProvider = providerKey === "google";
  }

  checkRoute(): void {
    const url = this.router.url;
    const parts = url.split("/");
    const providerKey = parts[parts.length - 1];
  }

  selectProvider(provider: any): void {
    this.router.navigate(["/user/news-letter", provider.key]).then(() => {
      this.setCurrentProvider();
    });
    NewsletterGuard.setProviderSelected(true);
  }

  getList() {
    try {
      let body = new HttpParams().set("user_id", this.userDetail._id);
      this.apiCallingService
        .callAPI(body, "POST", ConfigApi.URLS.FETCH_SMTP)
        .subscribe({
          next: (res) => {
            if (res?.success === true && res?.data.length === 0) {
              this.isShowOption = true;
            } else {
              this.isShowOption = false;
              if (res && res.data && Object.keys(res.data).length > 0) {
                this.fetchSmtp = res.data;

                this.apiCallingService.setFetchSmtpData(this.fetchSmtp);
                let page = this.page == 0 ? 0 : this.page - 1;

                let newPayload = new HttpParams()
                  .set("user_id", this.userDetail._id)
                  .set("page", page)
                  .set("limit", this.pageSize);
                this.apiCallingService
                  .callAPI(
                    newPayload,
                    "POST",
                    ConfigApi.URLS.CUSTOMER_LIST +
                      "?page=" +
                      page +
                      "&limit=" +
                      this.pageSize
                  )
                  .subscribe({
                    next: (customerList) => {
                      this.data = customerList.data;
                      this.totalcount = customerList.total;
                      this.isShowOption = false;
                    },
                    error: (err) => {
                      console.error("Error fetching customer list:", err);
                      this.toastr.error("Failed to fetch customer list.");
                    },
                  });
              } else {
                this.isShowOption = true;
              }
            }
          },
          error: (err) => {
            console.error("Error fetching SMTP details:", err);
            this.isShowOption = true;
          },
        });
    } catch (error) {
      console.error("Unexpected error:", error);
      this.isShowOption = true;
    }
  }

  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
      size: title === "Newsletter" ? "lg" : "md",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);
  }

  editSmtp() {
    if (this.fetchSmtp.type === "custom_smtp") {
      this.router.navigate(["/user/news-letter/smtp"]);
      NewsletterGuard.setProviderSelected(true);
    } else {
      this.router.navigate(["/user/news-letter/google"]);
      NewsletterGuard.setProviderSelected(true);
    }
  }

  deleteCustomer() {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.DELETE_CUSTOMER + "/" + this.detail._id
      )
      .subscribe({
        next: (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success("Successfully deleted");
            this.closeBtnClick();
            this.ngOnInit();
          }
        },
        error: (e) => {
          this.toastr.error(e.error.msg);
        },
        complete: () => console.info("complete"),
      });
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
        alert("Please upload a valid CSV file");
        return;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const text = e.target.result;
        const emails = this.processCSV(text);

        if (!emails.length) {
          this.toastr.warning("No valid emails found in CSV");
          return;
        }

        const payload: any = {
          user_emails: emails,
          user_id: this.userDetail._id,
        };

        this.apiCallingService
          .callAPI(payload, "POST", ConfigApi.URLS.IMPORT_EMAIL)
          .subscribe({
            next: (res) => {
              this.toastr.success(res.msg);
              this.ngOnInit();

              const fileInput = document.querySelector(
                'input[type="file"]'
              ) as HTMLInputElement;
              if (fileInput) {
                fileInput.value = "";
              }
            },
            error: (err) => {
              console.error("Error fetching customer list:", err);
              this.toastr.error("Failed to fetch customer list.");
            },
          });
      };
      reader.readAsText(file);
    }
  }

  private processCSV(csvText: string): string[] {
    const rows = csvText.split("\n");
    const emails: string[] = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    rows.forEach((row) => {
      const columns = row.split(",");
      columns.forEach((column) => {
        const cleanValue = column.trim();
        if (emailRegex.test(cleanValue)) {
          emails.push(cleanValue);
        }
      });
    });
    return [...new Set(emails)];
  }

  updateCustomer(): void {
    const updatedData = {
      userName: this.detail.user_name,
      email: this.detail.email,
    };

    this.apiCallingService
      .callAPI(
        updatedData,
        "PUT",
        `${ConfigApi.URLS.EDIT_CUSTOMER}/${this.detail._id}`
      )
      .subscribe({
        next: (res) => {
          if (res?.success) {
            this.toastr.success(res?.msg);
            this.closeBtnClick();
            this.ngOnInit();
          } else {
            this.toastr.error(res?.message || "Failed to update customer.");
          }
        },
        error: (err) => {
          console.error("Error updating customer:", err);
          this.toastr.error(err?.error?.msg);
        },
      });
  }

  onSubmitContent() {
    this.editorContent = "";
    this.showEditor = false;
    this.generatedImageUrl = null;

    if (this.FormDetails.content) {
      const payload = {
        content: this.FormDetails.content,
        image: true,
      };

      this.apiCallingService
        .callAPI(payload, "POST", ConfigApi.URLS.CONTENT_SEND_NEWSLETTER)
        .subscribe({
          next: (res) => {
            this.showEditor = true;
            this.generatedImageUrl = res?.data?.image;

            const parser = new DOMParser();
            const doc = parser.parseFromString(res?.data?.text, "text/html");
            const bodyContent = doc.body.innerHTML;
            const editorHtmlContent = bodyContent;
            this.editorContent = editorHtmlContent;
            this.toastr.success(res?.msg);
          },
          error: (err) => {
            console.error("Error sending newsletter:", err);
            this.toastr.error("Failed to send newsletter.");
          },
        });
    }
  }

  submitGeneratedContent() {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.editorContent, "text/html");
    const bodyContent = doc.body.innerHTML;

    const newPayload = {
      title: this.FormDetails.title,
      subject: this.FormDetails.subject,
      content: bodyContent,
      image: this.generatedImageUrl,
      user_id: this.userDetail._id,
    };

    this.apiCallingService
      .callAPI(newPayload, "POST", ConfigApi.URLS.SEND_NEWSLETTER)
      .subscribe({
        next: (res) => {
          this.showEditor = false;
          this.toastr.success(res?.msg);
          this.closeBtnClick();
        },
        error: (err) => {
          this.toastr.error(err?.error?.msg);
          this.closeBtnClick();
        },
      });
  }
  onEditorChange(event: any): void {
    this.isChangeEditor = true;
    this.editorContent = event?.editor?.getData();
  }

  trimLeadingWhitespace(value: any): string {
    return value.trimStart();
  }

  openImageModal(modal: any) {
    this.modalService.open(modal, {
      centered: true,
      backdrop: "static",
      size: "lg",
    });
  }

  regenerateImage() {
    const payload = {
      content: this.FormDetails.content,
    };

    this.apiCallingService
      .callAPI(payload, "POST", ConfigApi.URLS.REGENERATE_IMAGE)
      .subscribe({
        next: (res) => {
          this.generatedImageUrl = res?.data
            ? res.data
            : this.generatedImageUrl;
          this.toastr.success(res?.msg);
        },
        error: (err) => {
          this.toastr.error("Failed to regenerate image .");
        },
      });
  }

  closeBtnClick() {
    this.isShowOption = false;
    this.showEditor = false;
    this.FormDetails = {
      title: "",
      subject: "",
      content: "",
    };
    this.editorContent = "";
    this.modalService.dismissAll();
  }
  closeImageModal(modal: any) {
    modal.dismiss("Cross click");
  }
  ngOnDestroy(): void {
    NewsletterGuard.resetProviderSelected();
  }
}
