import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ConfigApi } from "../../../config-api";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiCallingService } from "../../../services/api-calling.service";
import { ToastrService } from "ngx-toastr";
import { NewsletterGuard } from "src/app/shared/newsletterGaurd/newsletter-guard.guard";

interface SmtpData {
  sender_name?: string;
  email?: string;
  password?: string;
  smtp_host?: string;
  port?: number;
  encryption?: string;
}

@Component({
  selector: "app-smtp-form",
  templateUrl: "./smtp-form.component.html",
  styleUrls: ["./smtp-form.component.css"],
})
export class SmtpFormComponent implements OnInit {
  userDetail: { _id: string } = { _id: "" };
  fetchSmtpData: SmtpData = {};
  formData: any = {
    senderName: "",
    email: "",
    password: "",
    smtpHost: "",
    smtpPort: 465,
    encryption: "None",
  };
  isShowChangeButton: boolean = false;
  constructor(
    public router: Router,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    let data: any = localStorage.getItem("Auth-user");
    this.userDetail = JSON.parse(data);

    this.fetchSmtpData = this.apiCallingService.getFetchSmtpData();

    this.isShowChangeButton = Object.keys(this.fetchSmtpData).length > 0;

    this.formData = {
      senderName: this.fetchSmtpData?.sender_name ?? "",
      email: this.fetchSmtpData?.email ?? "",
      password: this.fetchSmtpData?.password,
      smtpHost: this.fetchSmtpData?.smtp_host,
      smtpPort: this.fetchSmtpData?.port,
      encryption:
        this.fetchSmtpData?.encryption == "null"
          ? "None"
          : this.fetchSmtpData?.encryption,
    };
  }
  showPassword = false;

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  onSubmit(form: any): void {
    if (form.valid) {
      let body = {
        smtpEmail: form.value.email.trim(),
        smtpPass: form.value.password.trim(),
        smtpPort: form.value.smtpPort,
        senderName: form.value.senderName.trim(),
        smtpHost: form.value.smtpHost.trim(),
        encryption:
          form.value.encryption === "None"
            ? null
            : form.value.encryption.trim(),
        type: "custom_smtp",
        user_id: this.userDetail._id,
      };

      let url = ConfigApi.URLS.SMTP;

      let method = "POST";
      this.apiCallingService.callAPI(body, method, url).subscribe({
        next: (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.router.navigate(["/user/news-letter"]);
          }
        },
        error: (e) => {
          this.toastr.error(e.error.msg);
        },
      });
    }
  }

  changeToGoogle(): void {
    this.router.navigate(["/user/news-letter/google"]);
    NewsletterGuard.setProviderSelected(true);
  }
  resetForm(form: { resetForm: () => void }): void {
    form.resetForm();
  }

  ngOnDestroy(): void {
    NewsletterGuard.resetProviderSelected();
  }
}
