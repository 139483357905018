<div class="back-button-container mb-3">
  <button
    (click)="router.navigate(['/user/news-letter'])"
    class="btn-primary back-btn"
    ngbTooltip="Back to Newsletter"
    placement="top"
  >
    <i class="fas fa-arrow-left"></i>
  </button>
</div>
<div class="container">
  <div class="gmail-form-section">
    <div class="header">
      <img src="assets/images/Google_Icons.webp" alt="Google" class="logo" />
      <h2>Google</h2>
      <h3>Connect your Gmail account</h3>
    </div>

    <form #gmailForm="ngForm" (ngSubmit)="onSubmit(gmailForm)" novalidate>
      <div class="form-group">
        <label for="senderName">Sender Name</label>
        <input
          type="text"
          id="senderName"
          class="form-control"
          name="senderName"
          [(ngModel)]="formData.senderName"
          placeholder="Enter Name"
          required
          #senderName="ngModel"
        />
        <div
          *ngIf="senderName.invalid && senderName.touched"
          class="error-message"
        >
          Sender Name is required.
        </div>
      </div>

      <div class="form-group">
        <label for="email">Email Address</label>
        <div class="email-group">
          <input
            type="email"
            id="email"
            class="form-control"
            name="email"
            [(ngModel)]="formData.email"
            placeholder="Enter Email"
            required
            email
            #email="ngModel"
          />
          <div *ngIf="email.invalid && email.touched" class="error-message">
            <div *ngIf="email.errors?.required">Email is required.</div>
            <div *ngIf="email.errors?.email">
              Please enter a valid email address.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="appPassword">Password</label>
        <div class="password-group">
          <input
            [type]="showPassword ? 'text' : 'password'"
            id="appPassword"
            class="form-control"
            name="appPassword"
            [(ngModel)]="formData.appPassword"
            placeholder="Enter App Password"
            required
            #appPassword="ngModel"
          />

          <button
            type="button"
            class="password-toggle"
            (click)="togglePassword()"
          >
            <i
              class="fas"
              [class.fa-eye]="!showPassword"
              [class.fa-eye-slash]="showPassword"
            ></i>
          </button>
        </div>
        <div
          *ngIf="appPassword.invalid && appPassword.touched"
          class="error-message"
        >
          Password is required.
        </div>
      </div>

      <div *ngIf="isShowChangeButton" class="mt-4">
        <button
          class="change-smtp-btn p-2"
          type="button"
          (click)="changeToSmtp()"
        >
          <i class="fas fa fa-random mr-1" aria-hidden="true"></i> Change Google
          to SMTP
        </button>
      </div>

      <div class="button-group">
        <button type="button" class="btn btn-secondary" (click)="cancelForm()">
          Reset
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="gmailForm.invalid"
        >
          Connect
        </button>
      </div>
    </form>
  </div>
  <div class="instruction-container">
    <h4 class="instruction-title">
      Instructions to Connect Your Email Account Through the SMTP Method
    </h4>

    <div class="instructions">
      <ol>
        <li>
          <strong>Enable Two-Factor Authentication (2FA)</strong>
          <p>
            Ensure 2FA is enabled on your email account, as SMTP access requires
            it.
          </p>
        </li>
        <li>
          <strong>Create an App Password</strong>
          <p>
            Regular passwords won’t work with 2FA enabled. You must create an
            app password for SMTP configuration.
          </p>
        </li>
        <li>
          <strong>Generate an App Password</strong>
          <ul class="second-level-list">
            <li>Go to the App Passwords page.</li>
            <li>Sign in and enter a name for the app (e.g., "SMTP").</li>
            <li>
              Click
              <a
                href="https://myaccount.google.com/apppasswords"
                target="_blank"
                >Generate</a
              >, and a 16-character app password will appear.
            </li>
            <li>
              Copy the password immediately as it won’t be shown again. Use it
              for SMTP setup.
            </li>
          </ul>
        </li>
        <li>
          <strong>Need Help?</strong>
          <p>
            If you still need help connecting your SMTP email to MailOptimal,
            initiate a chat with us for support.
          </p>
        </li>
      </ol>
    </div>
  </div>
</div>
