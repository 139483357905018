<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title mb-0"><span class="lstick"></span>Coupons</h4>
        <div class="d-flex">
          <a
            href="javascript: void(0);"
            class="btn btn-primary ml-auto"
            placement="top"
            (click)="openModal(editModal, {}, 'Create Coupon')"
            ngbTooltip="Create Coupon"
          >
            + Create
          </a>
        </div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Coupon Code</th>
              <th scope="col">Coupon Type</th>
              <th scope="col">Offers</th>
              <th scope="col">Limits</th>
              <th scope="col">Expire on</th>
              <th scope="col">Have Multiple Use?</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.couponCode }}</td>

              <td>{{ d?.couponType }}</td>
              <td>
                {{
                  d?.couponType == "time"
                    ? d?.time + " " + d?.type
                    : d?.discountPercent + "%"
                }}
              </td>
              <td>{{ d?.limit }}</td>
              <td>{{ d?.expire_date | date : "dd/MM/yyyy" }}</td>
              <td>{{ d?.isMultipleUse ? "yes" : "no" }}</td>
              <td>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Edit Coupon')"
                  ngbTooltip="Edit"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Delete Coupon')"
                  ngbTooltip="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="8">No data found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form
      *ngIf="modeltitle == 'Create Coupon' || modeltitle == 'Edit Coupon'"
      (ngSubmit)="onSubmit(specialityform)"
      #specialityform="ngForm"
    >
      <div class="form-group row">
        <label for="couponCode" class="col-sm-4 col-form-label"
          >Coupon Code<br />

          <small *ngIf="modeltitle == 'Create Coupon'"
            >Create multiple coupon code using semi-colon <br /><b
              >Ex.(c1;c2)</b
            ></small
          ></label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            name="couponCode"
            [pattern]="pattern"
            [(ngModel)]="detail.couponCode"
            id="couponCode"
            name="couponCode"
            ngModel
            #couponCode="ngModel"
          />
          <div
            class="errmsg"
            *ngIf="couponCode.invalid && modeltitle == 'Create Coupon'"
          >
            Remove comma from the last
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="couponType" class="col-sm-4 col-form-label"
          >Select Coupon type
        </label>
        <div class="col-sm-8">
          <select
            class="form-control"
            #couponType="ngModel"
            name="couponType"
            [(ngModel)]="detail.couponType"
          >
            <option value="time">Time</option>
            <option value="discount">Percentage</option>
          </select>
        </div>
      </div>

      <div class="form-group row" *ngIf="detail.couponType == 'discount'">
        <label for="discountPercent" class="col-sm-4 col-form-label"
          >Discount in %</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            min="0"
            class="form-control"
            name="discountPercent"
            pattern="(\s*[^\s]+\s*)+"
            max="100"
            [(ngModel)]="detail.discountPercent"
            id="discountPercent"
            name="discountPercent"
            ngModel
            #discountPercent="ngModel"
            required
            appPercentage
          />
        </div>
      </div>

      <div class="form-group row" *ngIf="detail.couponType == 'time'">
        <label for="time" class="col-sm-4 col-form-label">Time</label>
        <div class="col-sm-8 form-group row">
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              min="0"
              name="time"
              pattern="(\s*[^\s]+\s*)+"
              [(ngModel)]="detail.time"
              id="time"
              name="time"
              ngModel
              #time="ngModel"
              required
            />
          </div>
          <div class="col-sm-6">
            <select
              class="form-control"
              #type="ngModel"
              name="type"
              [disabled]="true"
              [(ngModel)]="detail.type"
            >
              <option value="minute">Minutes</option>
              <option value="hour">Hours</option>
              <option value="day">Day</option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="limit" class="col-sm-4 col-form-label">Limits</label>
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            min="1"
            name="limit"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.limit"
            id="limit"
            ngModel
            #limit="ngModel"
            required
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="expire_date" class="col-sm-4 col-form-label"
          >Expire date</label
        >
        <div class="col-sm-8">
          <input
            type="date"
            class="form-control"
            min="{{ today }}"
            name="expire_date"
            [(ngModel)]="detail.expire_date"
            id="expire_date"
            ngModel
            #expire_date="ngModel"
            required
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="isMultipleUse" class="col-sm-4 col-form-label"
          >&nbsp;</label
        >
        <div class="col-sm-8">
          <div class="custom-control custom-checkbox mr-sm-2 m-b-15">
            <input
              type="checkbox"
              name="isMultipleUse"
              [(ngModel)]="detail.isMultipleUse"
              class="custom-control-input"
              id="checkbox0"
              value="check"
              ngModel
              #isMultipleUse="ngModel"
            />
            <label class="custom-control-label" for="checkbox0"
              >Have Multiple Use</label
            >
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>

        <button
          type="submit"
          class="btn btn-secondary btn-raised mr-1"
          [disabled]="!specialityform.valid"
        >
          Submit
        </button>
      </div>
    </form>

    <form [formGroup]="editUser" *ngIf="modeltitle == 'Delete Coupon'">
      Are you sure want to delete?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="delete()">
          Delete
        </button>
      </div>
    </form>
  </div>
</ng-template>
