import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage,
} from "angular-web-storage";
import { Globals } from "../common/global";

import { Constants } from "../common/constants";
import { ConfigApi } from "../config-api";
import { Router, ActivatedRoute } from "@angular/router";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

@Injectable({
  providedIn: "root",
})
export class ApiCallingService {
  private nominatimApiUrl = "https://nominatim.openstreetmap.org/search";
  url: string = "";
  operatorDetails: any = "";

  constructor(
    public http: HttpClient,
    public local: LocalStorageService,
    private router: Router,
    public Globals: Globals
  ) {}
  /**
   * GET HAEDER
   * @return httpHeaders
   */

  getHeader() {
    this.operatorDetails = localStorage.getItem("Auth-user");
    this.operatorDetails = JSON.parse(this.operatorDetails);
    let httpHeaders = new HttpHeaders();
    httpHeaders.append("Accept", "application/json");
    httpHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    httpHeaders = httpHeaders.append("time_zone", timeZone);
    if (this.operatorDetails && this.operatorDetails._id != "") {
      httpHeaders = httpHeaders.append("user_id", this.operatorDetails._id);
    }

    return httpHeaders;
  }
  getLocationSuggestions(address: string): Observable<any> {
    const encodedAddress = encodeURIComponent(address);
    const apiUrl = `${this.nominatimApiUrl}?format=json&q=${encodedAddress}&countrycodes=IN`;
    return this.http.get(apiUrl);
  }
  getAuthHeader() {
    let token = localStorage.getItem("Auth-user");
    let httpHeaders = new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
      time_zone: timeZone,
    });
    return httpHeaders;
  }

  /**
   * Method will call without login
   * @param email
   * @param pwd
   * @return httpResponse
   */
  callAPI(body: object, type: String, url: string): Observable<any> {
    let headers: HttpHeaders = this.getHeader();
    let options = {
      headers: headers,
    };
    if (type == "POST") {
      return this.http.post(url, body, options);
    } else if (type == "PUT") {
      return this.http.put(url, body, options);
    } else if (type == "DELETE") {
      return this.http.delete(url, options);
    } else {
      return this.http.get(url, options);
    }
  }
  /**
   * Method will call with login
   * @param email
   * @param pwd
   * @return httpResponse
   */
  callAuthAPI(body: object, type: String, url: string): Observable<any> {
    let token = localStorage.getItem("Auth-user");
    if (!token) {
      this.router.navigate(["/authentication/login"]);
    }
    let headers: HttpHeaders = this.getAuthHeader();
    let options = {
      headers: headers,
    };
    if (type == "POST") {
      return this.http.post(url, body, options);
    } else if (type == "PUT") {
      return this.http.put(url, body, options);
    } else if (type == "DELETE") {
      return this.http.delete(url, options);
    } else {
      return this.http.get(url, options);
    }
  }

  callAPIwithFile(body: object, type: String, url: string): Observable<any> {
    let token = localStorage.getItem("Auth-user");
    if (!token) {
      this.router.navigate(["/authentication/login"]);
    }

    let options = {};
    if (type == "POST") {
      return this.http.post(url, body, options);
    } else if (type == "PUT") {
      return this.http.put(url, body, options);
    } else if (type == "DELETE") {
      return this.http.delete(url, options);
    } else {
      return this.http.get(url, options);
    }
  }

  customAPI(
    header: any,
    body: object,
    type: String,
    url: string
  ): Observable<any> {
    let headers: HttpHeaders = header;
    let options = {
      headers: headers,
    };
    if (type == "POST") {
      return this.http.post(url, body, options);
    } else if (type == "PUT") {
      return this.http.put(url, body, options);
    } else if (type == "DELETE") {
      return this.http.delete(url, options);
    } else {
      return this.http.get(url, options);
    }
  }

  handleSuccess(res: any) {
    if (res.success) {
      return true;
    } else {
      return false;
    }
  }

  private _fetchSmtpData: any = null;
  setFetchSmtpData(data: any) {
    this._fetchSmtpData = data;
  }

  getFetchSmtpData() {
    return this._fetchSmtpData;
  }
}
