import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiCallingService } from "../../../services/api-calling.service";
import { ToastrService } from "ngx-toastr";
import { ConfigApi } from "../../../config-api";
import { HttpParams } from "@angular/common/http";
import { NewsletterGuard } from "src/app/shared/newsletterGaurd/newsletter-guard.guard";

@Component({
  selector: "app-google-form",
  templateUrl: "./google-form.component.html",
  styleUrls: ["./google-form.component.css"],
})
export class GoogleFormComponent implements OnInit {
  userDetail: any = {};
  fetchSmtpData: any;
  showPassword = false;
  isShowChangeButton: boolean = false;
  constructor(
    public router: Router,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService
  ) {}

  formData = {
    senderName: "",
    email: "",
    appPassword: "",
  };
  ngOnInit(): void {
    let data: any = localStorage.getItem("Auth-user");
    this.userDetail = JSON.parse(data);

    this.fetchSmtpData = this.apiCallingService.getFetchSmtpData();
    this.isShowChangeButton = Object.keys(this.fetchSmtpData).length > 0;

    this.formData = {
      senderName: this.fetchSmtpData?.sender_name ?? "",
      email: this.fetchSmtpData?.email ?? "",
      appPassword: this.fetchSmtpData?.password,
    };
  }
  togglePassword() {
    this.showPassword = !this.showPassword;
  }
  cancelForm() {
    this.formData = {
      senderName: "",
      email: "",
      appPassword: "",
    };
  }

  onSubmit(form: any) {
    if (form.valid) {
      let body = {
        smtpEmail: form.value.email.trim(),
        smtpPass: form.value.appPassword.trim(),
        smtpPort: "",
        senderName: form.value.senderName.trim(),
        smtpHost: "",
        encryption:
          form.value.encryption === "None" ? null : form.value.encryption,
        type: "google",
        user_id: this.userDetail._id,
      };
      let url = ConfigApi.URLS.SMTP;

      let method = "POST";
      this.apiCallingService.callAPI(body, method, url).subscribe({
        next: (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.router.navigate(["/user/news-letter"]);
          }
        },
        error: (e) => {
          this.toastr.error(e.error.msg);
        },
      });
    }
  }
  changeToSmtp(): void {
    this.router.navigate(["/user/news-letter/smtp"]);
    NewsletterGuard.setProviderSelected(true);
  }

  ngOnDestroy(): void {
    NewsletterGuard.resetProviderSelected();
  }
}
