<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Sale Package
        </h4>
        <div class="d-flex">
          <a
            href="javascript: void(0);"
            class="btn btn-primary ml-auto"
            placement="top"
            (click)="openModal(editModal, {}, 'Create Package')"
            ngbTooltip="Create Package"
          >
            + Create
          </a>
        </div>
      </div>
      <div class="">
        <div></div>
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Price</th>
              <th scope="col">
                {{
                  this.operatorDetails &&
                  this.operatorDetails.tax_type &&
                  this.operatorDetails.tax_type
                    ? this.operatorDetails.tax_type
                    : "VAT"
                }}
              </th>
              <th scope="col">Is Exclusive</th>
              <th scope="col">Description</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d.type }}</td>
              <td>{{ d.price }} {{ d.currency.code }}</td>
              <td>{{ d.vat }}</td>
              <td>{{ d.isExclusive }}</td>
              <td>
                {{
                  d && d.pkg_desc && d.pkg_desc.length > 30
                    ? d.pkg_desc.substring(0, 30) + "..."
                    : d.pkg_desc
                    ? d.pkg_desc
                    : ""
                }}
              </td>
              <td>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Edit Package')"
                  ngbTooltip="Edit"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript:  void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Delete Package')"
                  ngbTooltip="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  (click)="viewCouponList(d)"
                  class="link mr-1"
                  placement="top"
                  ngbTooltip="View Coupons"
                >
                  <i
                    class="mdi mdi-ticket-percent"
                    style="font-size: larger"
                  ></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="data.length == 0">
              <td colspan="6">No data found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form
      *ngIf="modeltitle == 'Create Package' || modeltitle == 'Edit Package'"
      (ngSubmit)="onSubmit(specialityform)"
      #specialityform="ngForm"
    >
      Package will decide how much rent you will get when a person hire your
      assets.
      <div class="form-group row">
        <label for="fullName" class="col-sm-4 col-form-label">Lock type</label>
        <div class="col-sm-8">
          <select
            class="form-control"
            #type="ngModel"
            name="type"
            [(ngModel)]="detail.type"
          >
            <option *ngFor="let type of locktypes">{{ type }}</option>
          </select>
          <span
            >Note : You cannot create multiple package with same asset
            type.</span
          >
        </div>
      </div>

      <div class="form-group row">
        <label for="price" class="col-sm-4 col-form-label"
          >Fees ({{ currency }})</label
        >
        <div class="col-sm-8">
          <input
            type="double"
            class="form-control"
            name="price"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.price"
            id="base_price"
            name="price"
            ngModel
            #price="ngModel"
            required
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="vat" class="col-sm-4 col-form-label"
          >{{
            this.operatorDetails &&
            this.operatorDetails.tax_type &&
            this.operatorDetails.tax_type
              ? this.operatorDetails.tax_type
              : "VAT"
          }}
          (%)</label
        >
        <div class="col-sm-8">
          <input
            type="double"
            class="form-control"
            name="vat"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.vat"
            id="vat"
            step="0.01"
            min="0"
            name="vat"
            ngModel
            #vat="ngModel"
            required
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="pkg_desc" class="col-sm-4 col-form-label"
          >Description</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            name="pkg_desc"
            pattern="(\s*[^\s]+\s*)+"
            [(ngModel)]="detail.pkg_desc"
            id="pkg_desc"
            name="pkg_desc"
            ngModel
            #pkg_desc="ngModel"
            maxlength="100"
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="timing" class="col-sm-4 col-form-label"
          >Is
          {{
            this.operatorDetails &&
            this.operatorDetails.tax_type &&
            this.operatorDetails.tax_type
              ? this.operatorDetails.tax_type
              : "VAT"
          }}
          inculsive or exclusive?</label
        >
        <div class="col-sm-8">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              [(ngModel)]="detail.isExclusive"
              name="isExclusive"
              id="inlineRadio1"
              #isExclusive="ngModel"
              [value]="false"
            />
            <label class="form-check-label" for="inlineRadio1">Inculsive</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              [(ngModel)]="detail.isExclusive"
              name="isExclusive"
              id="inlineRadio2"
              [value]="true"
            />
            <label class="form-check-label" for="inlineRadio2">Exclusive</label>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>

        <button
          type="submit"
          class="btn btn-secondary btn-raised mr-1"
          [disabled]="!specialityform.valid"
        >
          Submit
        </button>
      </div>
    </form>

    <form [formGroup]="editUser" *ngIf="modeltitle == 'Delete Package'">
      Are you sure want to delete?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="delete()">
          Delete
        </button>
      </div>
    </form>
  </div>
</ng-template>
