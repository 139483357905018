<div *ngIf="isShowOption">
  <div class="provider-container">
    <div
      *ngFor="let provider of providers"
      class="provider-card"
      (click)="selectProvider(provider)"
    >
      <div class="provider-content">
        <div class="icon-container">
          <img
            [src]="provider.icon"
            [alt]="provider.name + ' icon'"
            class="provider-icon"
          />
        </div>
        <div class="provider-info">
          <h2 class="provider-name">{{ provider.name }}</h2>
          <p class="provider-description">{{ provider.description }}</p>
        </div>
      </div>
      <div class="arrow">
        <span>›</span>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="!isShowOption">
  <div class="col-12">
    <div class="card mb-4 card-body">
      <h4 class="card-title mb-2">SMTP Configuration</h4>

      <div *ngIf="fetchSmtp">
        <p class="mb-1"><strong>Host:</strong> {{ fetchSmtp.smtp_host }}</p>
        <p class="mb-1"><strong>Port:</strong> {{ fetchSmtp.port }}</p>
        <p class="mb-1"><strong>Email:</strong> {{ fetchSmtp.email }}</p>
      </div>
      <div *ngIf="!fetchSmtp">
        <p>No SMTP configuration found.</p>
      </div>
      <button
        class="btn btn-primary mt-2 btn-md"
        (click)="editSmtp()"
        style="width: fit-content"
      >
        Edit configuration
      </button>
    </div>
    <div class="card card-body">
      <div class="rent-wrapper">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Customer List
          {{ totalcount ? "(" + totalcount + ")" : "" }}
        </h4>
        <div class="d-flex">
          <button
            class="btn btn-primary pull-right mr-4"
            (click)="openModal(editModal, d, 'Newsletter')"
            [disabled]="data && data.length === 0"
          >
            Create Newsletter
          </button>

          <a
            href="javascript: void(0);"
            class="btn btn-primary pull-right"
            placement="top"
            (click)="fileInput.click()"
            ngbTooltip="Import as CSV"
          >
            <i class="fas fa-file-alt"></i> Import Email
          </a>
          <input
            type="file"
            #fileInput
            (change)="onFileSelected($event)"
            accept=".csv"
            style="display: none"
          />
        </div>
      </div>
      <div class="">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Username</th>
              <th scope="col">Email</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data">
              <td>{{ d?.user_name || "-" }}</td>
              <td>{{ d?.email || "-" }}</td>
              <td>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Edit Customer')"
                  ngbTooltip="Edit"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, d, 'Delete Customer')"
                  ngbTooltip="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="!data || data.length === 0">
              <td colspan="4">No data found</td>
            </tr>
          </tbody>
        </table>
        <div
          class="d-flex justify-content-center mt-5"
          *ngIf="data && data.length !== 0"
        >
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #layoutModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Newsletter Preview</h5>
    <button type="button" class="close" (click)="closeImageModal(modal)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="p-2">
      <img
        [src]="generatedImageUrl"
        alt="Newsletter Image"
        class="img-fluid mb-3"
      />
      <div [innerHtml]="editorContent"></div>
    </div>

    <div class="d-flex justify-content-end">
      <button class="btn btn-secondary" (click)="closeImageModal(modal)">
        Close
      </button>
    </div>
  </div>
</ng-template>
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>

    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="modeltitle === 'Edit Customer'">
      <div class="form-group row">
        <label for="username" class="col-sm-4 col-form-label">Username</label>
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            id="username"
            [(ngModel)]="detail.user_name"
            name="username"
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-sm-4 col-form-label">Email</label>
        <div class="col-sm-8">
          <input
            type="email"
            class="form-control"
            id="email"
            [(ngModel)]="detail.email"
            name="email"
          />
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="updateCustomer()"
        >
          Save Changes
        </button>
      </div>
    </div>
    <div *ngIf="modeltitle == 'Newsletter'">
      <form #newsletterForm="ngForm">
        <div class="form-group">
          <label for="title">Title <span class="text-danger">*</span></label>
          <input
            type="text"
            id="title"
            placeholder="Enter Title"
            class="form-control"
            name="title"
            [(ngModel)]="FormDetails.title"
            required
            #title="ngModel"
            (input)="
              FormDetails.title = trimLeadingWhitespace($event.target.value)
            "
          />
          <div *ngIf="title.invalid && title.touched" class="text-danger">
            Title is required.
          </div>
        </div>
        <div class="form-group">
          <label for="subject">Subject</label>
          <input
            type="text"
            placeholder="Enter Subject"
            id="subject"
            class="form-control"
            name="subject"
            [(ngModel)]="FormDetails.subject"
            #subject="ngModel"
          />
        </div>
        <div class="form-group">
          <div class="d-flex justify-content-between align-items-center">
            <label for="content"
              >Describe Your Newsletter Idea
              <span class="text-danger">*</span></label
            >
            <button
              class="btn btn-primary mt-2"
              type="submit"
              (click)="onSubmitContent()"
              [disabled]="content.invalid"
            >
              Generate with AI
            </button>
          </div>
          <small class="form-text text-muted">
            Note : Please provide a detailed description of your newsletter
            idea. So will help to generate meaningful content.
          </small>
          <textarea
            id="content"
            class="form-control"
            name="content"
            placeholder="Write an idea or brief description, and AI will generate a tailored newsletter for you!"
            [(ngModel)]="FormDetails.content"
            required
            minlength="50"
            rows="5"
            #content="ngModel"
            (input)="
              FormDetails.content = trimLeadingWhitespace($event.target.value)
            "
          ></textarea>
          <div
            *ngIf="content.errors?.required && content.touched"
            class="text-danger"
          >
            Describe Your Newsletter Idea is required.
          </div>
          <div
            *ngIf="content.errors?.minlength && content.touched"
            class="text-danger"
          >
            Newsletter description must be at least 50 characters.
          </div>
        </div>
        <div class="mb-5 mt-4" *ngIf="showEditor">
          <div *ngIf="showEditor && generatedImageUrl">
            <img
              [src]="generatedImageUrl"
              alt="Newsletter Image Preview"
              class="img-thumbnail preview-image mb-3"
              style="max-width: 180px; max-height: 180px; cursor: pointer"
              (click)="openImageModal(imageModal)"
              ngbTooltip="View image preview"
            />
          </div>

          <ng-template #imageModal let-modal>
            <div class="modal-header">
              <h5 class="modal-title">Generated Newsletter Image</h5>
              <button
                type="button"
                class="close"
                (click)="closeImageModal(modal)"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              <img
                [src]="generatedImageUrl"
                alt="Newsletter Image"
                class="img-fluid mb-3"
              />
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary mr-2"
                  (click)="regenerateImage()"
                >
                  Regenerate Image
                </button>
                <button
                  class="btn btn-secondary"
                  (click)="closeImageModal(modal)"
                >
                  Close
                </button>
              </div>
            </div>
          </ng-template>

          <ckeditor
            name="editorContent"
            [editor]="Editor"
            [config]="editorConfig"
            [(ngModel)]="editorContent"
            (change)="onEditorChange($event)"
          ></ckeditor>
        </div>

        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            (click)="openImageModal(layoutModal)"
            *ngIf="showEditor"
          >
            Preview Layout
          </button>
          <button
            class="btn btn-primary"
            [disabled]="newsletterForm.invalid || !showEditor"
            (click)="submitGeneratedContent()"
          >
            Send Mail
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="modeltitle == 'Delete Customer'">
      Are you sure want to delete?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="deleteCustomer()">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>
